import * as constants from '../constants/CONSTANT';
import authApi from "./authApi";



const SparkApi = {
  //************************ Leads ***********************************//


  //................... Create Lead ................................
  async createLead(lead) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/leads",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(lead),

      });
    return await response.json();
  },

  //................... Create Backup ................................
  async createBackup() {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/backup",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        }
        // body: JSON.stringify(lead),

      });
    return await response.json();
  },

  //...................  Fetch All Lead ................................
  async fetchLead() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //...................  Fetch All Lead ................................
  async fetchBackups() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/backup", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },
  async downloadBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/download/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    console.log('filebody in api ', fileBody)
    return fileBody;

  },

  async deleteBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/delete/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    return await response.json();


  },


  //.............. Fetch Lead By Id .............................

  async fetchLeadById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //...................  Fetch All Business ................................
  async fetchBusiness() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/businesses", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Business By Id .............................

  async fetchBusinessById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/businesses/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },
  // Added by Farhan Khan on 10-oct-2023 this is using to fetch Phone No. from lead Table on Insert Case.
  //**********Fetch Lead By Phone *************** */
  async fetchLeadByPhone(ph) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/p/" + ph, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },
  //End Here Farhan Khan on 10-Oct-2023


  //...................  Fetch All Lead ................................

  async fetchUsers() {
    //console.log('if call')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    //console.log('response',response)


    const result = await response.json();
    //console.log('result',result)
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Lead By Id .............................
  async fetchUserById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    console.log('response', result)
    return result;
  },

  //fetchMedicalTestItemByOwnerId
  async findLeadByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/ld/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },
  //.............. Update Lead .............................
  async updateLead(lead) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(lead),
      }
    );
    return await response.json();
  },

  //.............. Update Lead .............................
  async updateBusiness(business) {
    console.log('business:', business);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/businesses/" + business.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(business),
      }
    );
    return await response.json();
  },

  // .......... Delete Lead .............................
  async deleteLead(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },

  //************************ Contacts ***********************************//

  //................... Create Contact ................................
  async createContact(contact) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/contacts",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(contact),

      });
    return await response.json();
  },

  async saveContact(contact) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/contacts/" + contact.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(contact),
      }
    );
    return await response.json();
  },

  //................... Fetch All Contacts ................................
  async fetchContacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //----------------------------------Releted Contacts-----------------------------------
  async findContactByAccountId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/" + id + "/contacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },


  //************************ Task ***********************************//
  async fetchTasks(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;

  },

  //************************ Task ***********************************//
  async fetchBusinessByParent(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/businesses/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;

  },

  async fetchTasksWithoutParent() {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/tasks/opentasks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {

      return result;
    }
    return null;

  },

  async fetchAllMeetings(today) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/tasks/meetings/today", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {

      return result;
    }
    return null;

  },

  async createTask(task) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(task),

      });

    return await response.json();

  },

  async saveTask(task) {
    //console.log(task.id)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + task.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(task),
      }
    );

    return await response.json();

  },

  async deleteTask(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },


  // ***********************************Home Page***************************************** /api/common/newleads

  //...................  Fetch All Converted Leads ................................
  async fetchConvertedLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/newconvertedleads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log('result', result)
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  //...................  Fetch All Not Converted Leads ................................
  async fetchNotContactedLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/newnotcontactedleads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log('result', result)
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },


  //...................  Fetch All New Leads ................................
  async fetchNewLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/newleads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async fetchallcontacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/allcontacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async fetchTotalBusiness() {
    console.log('business call:');
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/totalbusiness", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },


  async fetchallActiveusers() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/activeusers", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async fetchLeadReports(reportname) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/byname/" + reportname, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log("Rsult", result);
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async getCurrentUserTrackingRec() {
    const token = localStorage.getItem("token");
    //console.log("getCurrentUserTrackingRec");
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/track/currentrecord",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //console.log("current record from api  in fetch =>", result);
    if (result) {
      ////console.log('result in fetch => ', result)
      return result;
    }
    return null;
  },

  async saveStaffMemberEditProfile(userid, selectedFiles, staffMember) {
    //console.log("Enter staff data", userid);
    //console.log('selectedFiles',selectedFiles)
    const formData = new FormData();
    formData.append('file', selectedFiles);
    formData.append('staffRecord', staffMember);
    //console.log([...formData])
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/auth/" +
      userid + "/profile",
      {
        method: "PUT",
        mode: "cors",
        headers: {

          Authorization: token,
        },
        body: formData,
      }
    );
    //console.log(response);
    return await response.json();
  },


  async createCheckInRecord(locationRecord) {
    const token = localStorage.getItem("token");
    //console.log("UserLocationRecord => ", locationRecord);

    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(locationRecord),
    });

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //console.log("result in fetch =>", result);
    if (result) {
      ////console.log('result in fetch => ', result)
      return result;
    }
    return null;
  },
  async fetchUsertrackingsWithstaffId(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings/staff/" + staffId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    //console.log('result', result)
    if (result.length > 0) {
      return result;
    }
    return null;

  },


  async fetchSystemNotifications(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/notifications", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    //console.log('result', result)
    if (result && result.length > 0) {
      return result;
    }
    return null;

  },


  async handlCheckOut(locationRecord) {
    const token = localStorage.getItem("token");
    //console.log("handlCheckOut => ", locationRecord);
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/" + locationRecord.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(locationRecord),
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log("rsult in checkout => ", result);
    if (result) {
      return result;
    }
    return null;
  },


  async getLoginUserData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/getuser", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    //console.log('----->', JSON.stringify(result))

    return result;

  },




  async fetchCompanySetting(settingName) {
    //console.log('--settingName--', settingName)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/settings/" + settingName, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });

    const result = await response.json();
    //console.log('result', result);
    //console.log(response)
    return result;


  },





  async fetchFiles(parentId) {
    //console.log('--parentid--', parentId)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + parentId + "/all", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });

    const result = await response.json();
    //console.log('result', result);
    //console.log(response)
    if (result.length > 0) {
      return result;
    }
    return null;

  },




  async updateUser(user) {
    //console.log(user)
    const token = localStorage.getItem("token");
    let response = await fetch(

      constants.API_BASE_URL + "/api/auth/updatepassword",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),
      }
    );

    return await response.json();

  },



  async downloadFiles(fileid) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + fileid + "/download",
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    console.log('filebody in api ', fileBody)
    return fileBody;

  },


  //************************ Accounts ***********************************//

  //.................... Crate Account ...................................
  async createAccount(account) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/accounts",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(account),

      });
    return await response.json();

  },

  async saveAccount(account) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/accounts/" + account.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(account),
      }
    );
    return await response.json();

  },

  //.......... Fetch All Accounts ..........................................
  async fetchAccounts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Account By Id .............................
  async fetchAccountById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //............. Delete Account ............................
  async deleteAccount(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/accounts/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //************************ Contacts ***********************************//

  //................... Create Contact ................................







  async createUser(user) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/auth/createuser",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(user),

      });
    //console.log('response',response)
    return await response.json();

  },




  async saveUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/" + user.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),

      }
    );
    //console.log('response',response)

    return await response.json();

  },



  //.............. Fetch Contact By Id .............................
  async fetchContact(id) {
    console.log(id);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Contact By Recordtype Id .............................
  async fetchContactByRecordtype(rtype) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/t/" + rtype, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Contact By Recordtype Id .............................
  async fetchContactByPhone(ph) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/p/" + ph, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  // .......... Delete Contact .............................
  async deleteContact(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/contacts/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },




  //************************ Products ***********************************//


  // .................. create product ...................................
  async createProduct(product) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(product),

      });
    return await response.json();
  },

  async saveLead(lead) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(lead),
      }
    );
    return await response.json();
  },

  //.................. save Product ............................/
  async saveProduct(product) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + product.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(product),
      }
    );
    return await response.json();

  },

  //................... Fetch All Products ..............................//
  async fetchProducts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //.............. Fetch Product By Id .............................
  async fetchProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //............. Delete Product .........................
  async deleteProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //********************* Price Book *************************************

  //.................... Crate Pricebook ...................................
  async createPricebook(pricebook) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(pricebook),

      });
    return await response.json();
  },

  async savePricebook(pricebook) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricebooks/" + pricebook.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(pricebook),
      }
    );
    return await response.json();

  },

  //.......... Fetch All Pricebooks........................................
  async fetchPricebooks() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Pricebook By Id .............................
  async fetchPricebook(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },

  //.............. Fetch Pricebook By Owner Id .............................
  async fetchPricebookByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id + "/pid", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },

  //............. Delete Pricebook ............................
  async deletePricebook(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricebooks/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },



  //************* Payment ****************************/

  //.................... Create Payment ...................................
  async createPayment(payments) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(payments),

      });
    return await response.json();
  },

  async savePayment(payment) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/payments/" + payment.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(payment),
      }
    );
    return await response.json();

  },
  //.............. Fetch Payment By Owner Id .............................
  async fetchPaymentByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments/" + id + "/pid", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },

  //.............. Fetch Sum Amount By Owner Id .............................
  async findSumOfAmountByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments/" + id + "/amt", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },


  //****************** File  *******************

  async createFile(pid, formData) {
    console.log('pid', pid, 'formData', formData)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + pid,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Authorization": token,
        },
        body: formData,
      }
    );

    return await response.json();
  },

  //************************ files ***********************************//

  async saveFiles(file) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(file),
      }
    );

    return await response.json();

  },



  async convertLeadToContact(leadId, convertStatus) {
    console.log('convertStatus', convertStatus)

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/convert/" + leadId, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ status: convertStatus }),

    });
    //console.log('===>',response)
    const result = await response.json();
    //console.log(result)
    if (result) {
      //console.log(result)
      return result;
    }
    return null;
  },


  async deleteFile(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },

  // --------------------------------- Report ----------------------------------------

  async fetchReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },


  async fetchReportsById(row) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/" + row.id,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
    return await response.json();
  },

  //.............. Fetch Total Count of Accounts .............................
  async fetchCountOfAccounts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/cnt", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Total Count of Doctors in Contact......................
  async fetchCountOfDoctors() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/total/dr", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Total Count of Patients in Contact......................
  async fetchCountOfPatients() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/total/pt", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  // --------------------------------- Commission ----------------------------------------
  async fetchCommissions() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createCommissions(commisson) {
    //console.log('commisson',commisson)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(commisson),
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async saveCommissions(commission) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions/" + commission[0].id, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(commission),
    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async deleteCommissions(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions/" + id, {
      method: "DELETE",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
}
export default SparkApi