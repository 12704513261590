//export const API_BASE_URL = `${window.location.protocol}//${window.location.hostname}/ibs`;
// export const API_BASE_URL = "http://localhost:3003";
export const API_BASE_URL= "https://admin.eecoindia.com/ibs";

export const VIEW_LEAD = "VIEW_LEAD";
export const VIEW_PROPERTY = "VIEW_PROPERTY";
export const EDIT_LEAD = "EDIT_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";
export const VIEW_PRODUCT = "VIEW_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_ALL = "MODIFY_ALL";
export const VIEW_ALL = "VIEW_ALL";
export const VIEW_ORDER = "VIEW_ORDER";
export const VIEW_USER = "VIEW_USER";
export const VIEW_CONTACT = "VIEW_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const VIEW_ACCOUNT = "VIEW_ACCOUNT";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const VIEW_BUSINESS = "VIEW_BUSINESS";
export const EDIT_BUSINESS = "EDIT_BUSINESS";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
//FILE TYPES
export const PDF = "pdf";
export const DOC = "doc";
export const DOCX = "docx";
export const XLS = "xls";
export const XLSX = "xlsx";
export const CSV = "csv";
export const PNG = "png";
export const JPG = "JPG";
export const JPEG = "jpeg";

// export const LEAD_STATUS_VALUES = [{label : 'Open - Not Contacted', islast : false}, {label : 'Working Contacted', islast : false} , {label : 'Closed - Converted', islast : true}, {label : 'Closed - Not Converted', islast : true}];
export const LEAD_STATUS_VALUES =[
        {
            "label": "Contacted",
            "sortorder": 1,
            "is_converted": false,
            "is_lost": false,
            'color': 'bs-orange',
            "className":"info"
           
        },
        {
            "label": "Not-Contacted",
            "sortorder": 2,
            "is_converted": false,
            "is_lost": false,
            'color': 'bs-blue',
            "className":"warning"

        },
        {
            "label": "Intrested",
            "sortorder": 3,
            "is_converted": false,
            "is_lost": false,
            'color': 'bs-yellow',
            "className":"primary"
        },
        {
            "label": "Not-Intrested",
            "sortorder": 4,
            "is_converted": false,
            "is_lost": false,
            'color': 'bs-red',
            "className":"secondary"
        },
        {
            "label": "Converted",
            "sortorder": 5,
            "is_converted": true,
            "is_lost": false,
            'color': 'bs-green',
            "className":"success"
        },
        {
            "label": "Not-Converted",
            "sortorder": 6,
            "is_converted": false,
            "is_lost": true,
            'color': 'bs-red',
            "className":"danger"
        }
    ]
    export const Project_Stage =
    [
        {
            label: "DPC",
            sortorder: 1,
           
        },
        {
            label: "GF",
            sortorder: 2,
           
        },
        {
            label: "Initial/Planning",
            sortorder: 3,
           
        },
        {
            label: "FF",
            sortorder: 4,
           
        },
        {
            label: "SF",
            sortorder: 5,
           
        },
        {
            label: "Roof Casting",
            sortorder: 6,
            
        },
        {
            label: "Tiling/Flooring",
            sortorder: 7,
           
        },
        {
            label: "Plaster",
            sortorder: 8,
           
        },
        {
            label: "Plumbing",
            sortorder: 9,
           
        },
        {
            label: "Furniture",
            sortorder: 10,
           
        },
        {
            label: "Finishing",
            sortorder: 11,
            
        },
        {
            label: "Paints",
            sortorder: 12,
           
        },
       
    ];
    export const Lead_Type =
    [
        
        {
            label: "Architects",
            sortorder: 1,
           
        },
        {
            label: "Builder",
            sortorder: 2,
           
        },
        {
            label: "Retailers",
            sortorder: 3,
           
        },
        {
            label: "Dealer",
            sortorder: 4,
           
        },
        {
            label: "Distributors",
            sortorder: 5,
           
        },
        {
            label: "Applicator",
            sortorder: 6,
            
        },
        {
            label: "Engineer",
            sortorder: 7,
        },
        {
            label: "Customer",
            sortorder: 8,
            
        },
        {
            label: "Shop Owner",
            sortorder: 9,
            
        },
        {
            label: "IHB",
            sortorder: 10,
            
        },
        {
            label: "Contractor",
            sortorder: 11,
            
        },
        {
            label: "Water-Proofing",
            sortorder: 12,
            
        },
        {
            label: "Other",
            sortorder: 13,
            
        },
        
    ];

   
    //export const EMAIL_ADDRESS = ['waz.scibot@hotmail.com', 'wazidmohammad07@gmail.com'];
