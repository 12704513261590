import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import SparkApi from '../../api/SparkApi';

const PieChart = () => {
  //const [leadStatusCounts, setLeadStatusCounts] = useState([]);
  const [leadTypesCounts, setLeadTypesCounts] = useState([]);
  //const [ownerNames, setOwnerNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //const leadCountByAgent = await SparkApi.fetchLeadReports('lead_count_by_agent');
      const leadCountByType = await SparkApi.fetchLeadReports('lead_count_by_status');
     // console.log('leadCountByType', leadCountByType);
      let labelCounts = {};
      //let allLeadStatus = JSON.parse(localStorage.getItem("lead_status"));
      let allLeadTypes = JSON.parse(localStorage.getItem("lead_status"));
      //console.log('allLeadTypes', allLeadTypes);
      //let convertedLeadStatus = allLeadStatus.filter(function (item) {
        let convertedLeadType = allLeadTypes.filter(function (item) {
        return item.is_converted === false;
      });
      //console.log('convertedLeadStatus:', convertedLeadStatus);

      allLeadTypes.forEach((resultItem) => {
      //allLeadStatus.forEach((resultItem) => {
        //leadCountByAgent.forEach((allresultItem) => {
          leadCountByType.forEach((allresultItem) => {
           // console.log(resultItem.label,"----------",allresultItem.leadstatus)
            //console.log(allresultItem.leadstatus,labelCounts.hasOwnProperty(allresultItem.ownername))
          if (resultItem.label === allresultItem.leadstatus) {
            if (labelCounts.hasOwnProperty(allresultItem.leadstatus)) {
              labelCounts[allresultItem.leadstatus].count += parseInt(allresultItem.count);
            } else {
              labelCounts[allresultItem.leadstatus] = { name: allresultItem.leadstatus, count: parseInt(allresultItem.count) };
            }
          }
        });
      });
      // console.log('allLeadTypes', allLeadTypes)
      // console.log('leadCountByType',leadCountByType)
       //console.log('leadTypesCounts', leadTypesCounts )
      console.log('labelCounts', labelCounts);
       console.log('99999',Object.values(labelCounts))

     //setLeadStatusCounts(Object.values(labelCounts));
      setLeadTypesCounts(Object.values(labelCounts));
    };

    fetchData();
  }, []);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     const leadCountByType = await SparkApi.fetchLeadReports('lead_count_by_status');
  //     let labelCounts = {};

  //     leadCountByType.forEach((resultItem) => {
  //       if (labelCounts.hasOwnProperty(resultItem.leadstatus)) {
  //         labelCounts[resultItem.leadstatus] += parseInt(resultItem.count);
  //       } else {
  //         labelCounts[resultItem.leadstatus] = parseInt(resultItem.count);
  //       }
  //     });

  //     const data = Object.entries(labelCounts).map(([name, count]) => ({ name, count }));
  //     setLeadTypesCounts(data);
  //   };

  //   fetchData();
  // }, []);

  const options = {
    chart: {
      type: 'pie',
    },
    legend: {
      position: 'top',
    },
    colors: [
      /*'#fe9d00', //Contacted
      '#ff6384', //Not Contacted
      '#fff309',//Intrested 
      '#36a2eb', // NOt Intrested
      '#4bc0c0', // converted
      '#99994d', //Not Converted
*/
    // '#C84CB4', //not-converted
    // '#14A44D',  //conv
    // '#DC4C64', //not -int
    // '#3B71CA', //not-cont
    // '#54B4D3', //cont
    // '#E4A11B',  //intr

    // Updated Color Code Below (08-May-2024)
   

    "rgb(200, 76, 180)", //not-converted
    'rgb(255, 0, 0)',  //conv
    'rgb(124, 193, 215)', //not -int
    'rgb(59, 113, 202)', //not-cont
    'rgb(20, 164, 77)', //cont
    'rgb(228, 161, 27)',  //intr

      //'#FF0000',
    ],
    //labels: leadStatusCounts.map((item) => item.name),

    labels: leadTypesCounts.map((item) => item.name),
    dataLabels: {
      //enabled: false,
     enabled: false,
        style: {
         colors: ['#000000'], // Set the color to black
         textShadow: 'none'
       },
       dropShadow: {
        enabled: false, // Disable the drop shadow
      },
      // formatter: function (val) {
     
      //   //return parseInt (val);
      //  return `${val.toFixed(0)}%`;
        
      // },
        formatter: function (val, opts) {
         // return `${opts.w.globals.labels[opts.seriesIndex]}: ${val.toFixed(0)}%`;
         return opts.w.globals.labels[opts.seriesIndex];
        },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
          
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  };

  //const series = leadStatusCounts.map((item) => item.count);
  const series = leadTypesCounts.map((item) => item.count);
//console.log('series', series)
//console.log('options', options)
  return (
    <div className="chart-container">
      {/* <h2 className="chart-title">Lead Status Distribution</h2> */}
      {/* <div className="chart-wrapper">
      
      </div> */}
      <h2  className="chart-title">LEAD TYPES</h2>
    <div className="chart-wrapper">
      <ReactApexChart options={options} series={series} type="pie" />
    </div>
    </div>
  );
};

export default PieChart;
