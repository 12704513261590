import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import SparkApi from '../../api/SparkApi';

const PieChart2 = () => {
  //const [leadStatusCounts, setLeadStatusCounts] = useState([]);
  const [leadTypesCounts, setLeadTypesCounts] = useState([]);
  //const [ownerNames, setOwnerNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //const leadCountByAgent = await SparkApi.fetchLeadReports('lead_count_by_agent');
      const leadCountByType = await SparkApi.fetchLeadReports('project_stage_count_report');
      //console.log('leadCountByType', leadCountByType);
      let labelCounts = {};
      //let allLeadStatus = JSON.parse(localStorage.getItem("lead_status"));
      let allLeadTypes = JSON.parse(localStorage.getItem("project_Stage"));
     // console.log('allLeadTypes', allLeadTypes);
      //let convertedLeadStatus = allLeadStatus.filter(function (item) {
        /* let convertedLeadType = allLeadTypes.filter(function (item) {
        return item.is_converted === false;
      }); */
      //console.log('convertedLeadStatus:', convertedLeadStatus);

      allLeadTypes.forEach((resultItem) => {
      //allLeadStatus.forEach((resultItem) => {
        //leadCountByAgent.forEach((allresultItem) => {
          leadCountByType.forEach((allresultItem) => {
            //console.log(resultItem.label,"----------",allresultItem.project)
            //console.log(allresultItem.project,labelCounts.hasOwnProperty(allresultItem.ownername))
          if (resultItem.label === allresultItem.project) {
           // console.log('if lable call')
            if (labelCounts.hasOwnProperty(allresultItem.project)) {

              labelCounts[allresultItem.project].count += parseInt(allresultItem.count);
            } else {
              labelCounts[allresultItem.project] = { name: allresultItem.project, count: parseInt(allresultItem.count) };
            }
          }
        });
      });
      // console.log('allLeadTypes', allLeadTypes)
      // console.log('leadCountByType',leadCountByType)
      // console.log('leadTypesCounts', leadTypesCounts )
      // console.log('labelCounts', labelCounts);
      // console.log('99999',Object.values(labelCounts))

     //setLeadStatusCounts(Object.values(labelCounts));
      setLeadTypesCounts(Object.values(labelCounts));
    };

    fetchData();
  }, []);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     const leadCountByType = await SparkApi.fetchLeadReports('lead_count_by_status');
  //     let labelCounts = {};

  //     leadCountByType.forEach((resultItem) => {
  //       if (labelCounts.hasOwnProperty(resultItem.leadstatus)) {
  //         labelCounts[resultItem.leadstatus] += parseInt(resultItem.count);
  //       } else {
  //         labelCounts[resultItem.leadstatus] = parseInt(resultItem.count);
  //       }
  //     });

  //     const data = Object.entries(labelCounts).map(([name, count]) => ({ name, count }));
  //     setLeadTypesCounts(data);
  //   };

  //   fetchData();
  // }, []);

  const options = {
    chart: {
      type: 'pie',
    },
    legend: {
      position: 'top',
    },
    colors: [
       
     

       '#666699', //DPC
       '#ff8080',//GF 
       '#36a2eb', // Intial/Palning
       '#4dffff', //FF
       '#4bc0c0', // SF
       '#ff9966', //Roof Casting
        '#c266ff', //Tilling Floor
       '#FF0066', //Plaster
       '#e6e600',//Plumbing 
         '#CCCC99', // Furniture
       '#b3c6ff', // Finishing
       '#79d2a6', //Paints
        
      //'#FF0000',
    ],
    //labels: leadStatusCounts.map((item) => item.name),

    labels: leadTypesCounts.map((item) => item.name),
    dataLabels: {
      //enabled: false,
     enabled: true,
      style: {
         colors: ['#000000'], // Set the color to black
         textShadow: 'none',
      },
      dropShadow: {
        enabled: false, // Disable the drop shadow
      },
      // formatter: function (val) {
     
      //   //return parseInt (val);
      //  return `${val.toFixed(0)}%`;
        
      // },
        formatter: function (val, opts) {
          //return `${opts.w.globals.labels[opts.seriesIndex]}: ${val.toFixed(0)}%`;
          return opts.w.globals.labels[opts.seriesIndex];
        },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
          
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  };

  //const series = leadStatusCounts.map((item) => item.count);
  const series = leadTypesCounts.map((item) => item.count);
  //console.log('$$$ ', leadTypesCounts)
//console.log('series', series)
//console.log('options', options)
  return (
    <div className="chart-container">
      {/* <h2 className="chart-title">Lead Status Distribution</h2> */}
      {/* <div className="chart-wrapper">
      
      </div> */}
      <h2  className="chart-title">PROJECT STAGE</h2>
    <div className="chart-wrapper">
      <ReactApexChart options={options} series={series} type="pie" />
    </div>
    </div>
  );
};

export default PieChart2;
