import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
const AuthContext = createContext();
export const AuthProvider = ({ children, accessToken }) => {
  const [auth, setAuth] = useState({});
  //console.log(accessToken, "wait");
  useEffect(() => {
    if (accessToken) {
      try {
        if (accessToken) {
          const decodedToken = jwt_decode(accessToken);
          const permissions = decodedToken.permissions;

          setAuth({
            accessToken,
            permissions,
          });
        }
      } catch (error) {
        console.error("Error decoding access token:", error);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth, accessToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;