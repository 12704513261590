import React, { useEffect, useState } from "react";
import SparkApi from "../api/SparkApi";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import LeadList from "./LeadList";
import { Table } from "react-bootstrap";

const ReportPDF = (props) => {

    const [reportBody, setReporttest] = useState(props.reports ? props.reports : []);
    const [reportHeader, setReportHeader] = useState(props.headers ? props.headers : []);
    // const[leadinfo, setLeadinfo] = useState([]);
    let fields = props.fields;
    let values = props.values;
    let header = props.arrName;
    console.log('header', header)
    console.log('fields', props.fields)
    useEffect(() => {
        console.log('props.header', props.headers)
        console.log('report', props.report)
        if (props?.reports) {
            setReporttest(props?.reports);
            setReportHeader(props.headers);
        }
    }, [props.refresh]);



    return (

        <div >
            <Table striped bordered hover style={{ fontSize: "12px" }}>
                <thead>
                    <tr>
                        {reportHeader.map((data, index) => (
                            <th>{data.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {reportBody.map((data, index) => (
                        props.report.name === 'List of Lead Wise Report' && (<tr key={index}>
                            <td>{data.Visit_Date}</td>
                            <td>{data.Lead_Name}</td>
                            <td>{data.List_of_Lead}</td>
                            <td>{data.Location}</td>
                            <td>{data.amount}</td>
                            <td>{data.phone}</td>
                            <td>{data.city}</td>


                        </tr>

                        )))}
                    {reportBody.map((data, index) => (
                        props.report.name === 'Executive wise Report' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.Client_Name}</td>
                            <td>{data.phone}</td>
                            <td>{data.Lead_Type}</td>
                            <td>{data.leadstatus}</td>
                            <td>{data.Executive_Name}</td>
                            <td>{data.createdbyname}</td>
                            <td>{data.amount}</td>

                        </tr>

                        )))}
                    {reportBody.map((data, index) => (
                        props.report.name === 'Converted Lead Report' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.Lead_Type}</td>
                            <td>{data.Client_Name}</td>
                            <td>{data.phone}</td>
                            <td>{data.landmark}</td>
                            <td>{data.requirment}</td>
                            <td>{data.owner_name}</td>
                            <td>{data.amount}</td>

                        </tr>

                        )))}
                    {reportBody.map((data, index) => (
                        props.report.name === 'Location Wise Report' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.Client_Name}</td>
                            <td>{data.Lead_Type}</td>

                            <td>{data.amount}</td>
                            <td>{data.phone}</td>
                            <td>{data.street}</td>
                            <td>{data.city}</td>
                            <td>{data.othercity}</td>
                            <td>{data.Location}</td>
                            <td>{data.state}</td>

                        </tr>

                        )))}
                    <div style={{ fontSize: '6px' }}>
                        {reportBody.map((data, index) => (
                            props.report.name === 'Check In and Out Report' && (<tr key={index}>

                                <td>{data.Visit_Date}</td>
                                <td>{data.User_Name}</td>
                                <td>{data.Client_Name}</td>
                                {/* <td>{data.Lead_Type}</td> */}
                                <td>{data.logindatetime}</td>
                                <td>{data.logoutdatetime}</td>
                                <td>{data.location}</td>
                                <td>{data.remarks}</td>
                                <td>{data.amount}</td>
                            </tr>

                            )))}
                    </div>

                    {reportBody.map((data, index) => (
                        props.report.name === 'Not Contacted call records' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.Client_Name}</td>
                            <td>{data.owner_name}</td>
                            <td>{data.Lead_Type}</td>
                            <td>{data.phone}</td>
                            <td>{data.requirment}</td>
                            <td>{data.city}</td>
                            <td>{data.othercity}</td>
                            <td>{data.amount}</td>
                            
                        </tr>

                        )))}

                    {reportBody.map((data, index) => (
                        props.report.name === 'Project Stage Wise Report' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.Client_Name}</td>
                            <td>{data.Lead_Type}</td>
                            <td>{data.project}</td>
                            <td>{data.phone}</td>
                            <td>{data.city}</td>
                            <td>{data.requirment}</td>
                            <td>{data.amount}</td>
                        </tr>

                        )))}

                    {/* {reportBody.map((data, index) => (
                        props.report.name === 'Project Stage Count Report' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.amount}</td>
                            <td>{data.Lead_Type}</td>
                            <td>{data.project}</td>
                            <td>{data.Count}</td>
                        </tr>

                        )))} */}

                    {reportBody.map((data, index) => (
                        props.report.name === 'Product Wise Report' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.Client_Name}</td>
                            <td>{data.Lead_Type}</td>
                            <td>{data.product}</td>
                            <td>{data.phone}</td>
                            <td>{data.Location}</td>
                            <td>{data.description}</td>
                            <td>{data.amount}</td>

                        </tr>

                        )))}
                    {reportBody.map((data, index) => (
                        props.report.name === 'Not Converted Lead Report' && (<tr key={index}>

                            <td>{data.Visit_Date}</td>
                            <td>{data.Client_Name}</td>
                            <td>{data.Lead_Type}</td>
                            <td>{data.phone}</td>
                            <td>{data.landmark}</td>
                            <td>{data.requirment}</td>
                            <td>{data.owner_name}</td>
                            <td>{data.amount}</td>

                        </tr>

                        )))}
                    <tr style={{ border: "None" }}>
                        {reportHeader?.map((data, dataIndex) =>
                            <td key={dataIndex}></td> // A
                        )}
                    </tr>
                    {props.report && props.report.summarymethod && props.report.summaryfield && fields && fields.length > 0 &&
                        <tr style={{ border: "None" }}>

                            {header?.map((data, dataIndex) =>
                                fields.includes(data) ? (
                                    <td key={dataIndex}>Total : {values[data]}</td>
                                ) : (
                                    <td key={dataIndex}></td> // Add an empty cell if the field is not present in the data
                                )
                            )}
                        </tr>}
                </tbody>
            </Table>
        </div>

    );
};
export default ReportPDF;
