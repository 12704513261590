import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import Confirm from "./Confirm";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";
import * as constants from '../constants/CONSTANT';
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import LeadPDF from './LeadPDF'
import csvDownload from 'json-to-csv-export'
import { isMobile, MobileView, BrowserView } from 'react-device-detect';

const LeadList = () => {
  const [arrName, setArrName] = useState([]);
  const location = useLocation();
  const report = location.state;
  const [tempBody, setTempBody] = useState([]);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [fields, setFields] = useState([]);
  const [values, setValues] = useState([]);
  // const handleStartDateChange = (e) => {
  //   setStartDate(e.target.value);
  // };

  // const handleEndDateChange = (e) => {
  //   setEndDate(e.target.value);
  // };



  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leadname, setLeadName] = useState();
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [lead, setLead] = useState();
  ////console.log('location.state ='+location.state)
  const [leadStatusArray, setleadStatusArray] = useState(constants.LEAD_STATUS_VALUES);
  const [leadTypeArray, setleadTypeArray] = useState(constants.Lead_Type);
  const [regFormList, setRegFormList] = useState([]);
  const [serviceArea, setServiceArea] = useState(constants.Lead_Type);
  let [filterObj, setFilterObj] = useState({});
  const [refreshList, setRefreshList] = useState(Date.now());
  const [stocklist, setStockList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [stockid, setStockId] = useState('');

  useEffect(() => {
    console.log('isMobile', isMobile)

  }, []);

  //const [status, setStatus] = useState('Converted');

  //     useEffect(() => {
  //       fetchLead();

  //   }, []);
  //   const fetchLead = async () => {

  //     const result = await SparkApi.fetchLead();

  //     console.log(result)
  //     if (result) {
  //         setBody(result);
  //         setRegFormList(result);
  //     } else {
  //         setBody([]);
  //         setRegFormList([]);
  //     }
  // }

  const handleDelete = async (row) => {
    setModalShow(true);
    setStockId(row.id);
  }

  const deleteLead = async () => {
    setModalShow(false);
    let result = await SparkApi.deleteLead(stockid);
    console.log('result1', result)
    if (result.success) {
      console.log('result.success')
      result = await SparkApi.fetchLead();
      console.log('result@@@@@', result)
      if (result) {

        setRegFormList(result)
        //setStockList(result)
        setBody(result);
      } else {
        setBody([]);
        setRegFormList()
      }
    }
  }

  useEffect(() => {


    async function init() {
      const leads = await SparkApi.fetchLead();

      //console.log('leads', leads)
      if (leads) {
        //console.log("lead data =>", leads);
        let status = location.pathname.split("/")[2];
        setBody(leads);
        //setLead(leads);
        if (status === 'All') {
          setRegFormList(leads);
        } else {
          let tempData = leads.filter((item) => {
            if (!status || status === '' || item.leadstatus === status) {
              return item;
            }
          });

          setRegFormList(tempData);
        }

      } else {
        setBody([]);
        //setLead([]);
        setRegFormList([]);
      }
    }
    init();
    setLeadName(body.firstname + " " + body.lastname);
  }, [leadname]);

  const onFilterSelection = (event) => {
    setFilterObj({ ...filterObj, [event.target.name]: event.target.value });

  }

  useEffect(() => {
    doFilterData();
  }, [filterObj]);

  useEffect(() => {
    async function init() {
      //console.log(report)
      //console.log('reports',report.id)
      const result = await SparkApi.fetchReportsById(report);
      //console.log("api response", result);

      if (result && result.length) {
        setArrName(Object.keys(result[0]));
        setBody(result);
        setTempBody(result);
        console.log(Object.keys(result[0]))
        if (report && report.summarymethod && report.summaryfield) {
          //let totamount = 0;
          const methods = report.summarymethod.split(", ");
          const fieldArry = report.summaryfield.split(", ");
          //console.log('result',fieldArry)
          let obj = {};
          for (let field of fieldArry) {
            obj[field] = 0;
            for (let data of result) {
              for (let mth of methods) {
                if (mth === 'sum') {
                  obj[field] = obj[field] + Number(data[field])
                } else if (mth === 'count') {
                  obj[field] = obj[field] + 1;
                }
              }
            }
          }
          console.log(fieldArry, obj)
          setFields(fieldArry);
          setValues(obj);
          //console.log('totamount',totamount)
          //setTotalAmount(totamount);
        }
      } else {
        setBody([]);
        setTempBody([]);
        setArrName([]);
      }
    }

    // init()
  }, []);

  // const filterRecordsByDate = () => {
  //   console.log('startDate:', startDate);
  //   console.log('endDate:', endDate);
  //   console.log('body:', tempBody);

  //   if (startDate && endDate && tempBody.length > 0) {
  //     // Log the format of the first record's Visit_Date field
  //     // console.log('First record Visit_Date format:', moment(body[0].Visit_Date).format('YYYY-MM-DD'));

  //     const filteredRecords = tempBody.filter((record) => {
  //       console.log('record.Visit_Date', record.Visit_Date)
  //       const visitDate = moment(record.Visit_Date, 'DD-MM-YYYY').toDate();

  //       const start = moment(startDate).toDate();
  //       const end = moment(endDate).toDate();
  //       console.log('visitDate:', visitDate);
  //       console.log('startDate:', startDate);
  //       console.log('endDate:', endDate);
  //       console.log('visitDate >= start && visitDate <= end:', visitDate >= start && visitDate <= end);

  //       return visitDate >= start && visitDate <= end;
  //     });
  //     console.log('filteredRecords:', filteredRecords);
  //     if (report && report.summarymethod && report.summaryfield) {
  //       //let totamount = 0;
  //       const methods = report.summarymethod.split(", ");
  //       const fieldArry = report.summaryfield.split(", ");
  //       //console.log('result',fieldArry)
  //       let obj = {};
  //       for (let field of fieldArry) {
  //         obj[field] = 0;
  //         for (let data of filteredRecords) {
  //           for (let mth of methods) {
  //             if (mth === 'sum') {
  //               obj[field] = obj[field] + Number(data[field])
  //             } else if (mth === 'count') {
  //               obj[field] = obj[field] + 1;
  //             }
  //           }
  //         }
  //       }
  //       console.log(fieldArry, obj)
  //       setFields(fieldArry);
  //       setValues(obj);
  //       //console.log('totamount',totamount)
  //       //setTotalAmount(totamount);
  //     }
  //     setBody(filteredRecords); // Update the state with the filtered records
  //   }
  // };

  // useEffect(() => {
  //   filterRecordsByDate();
  // }, [startDate, endDate]);


  const doFilterData = (event) => {
    console.log('filterObj', filterObj)

    if (body) {
      let tempData = body.filter((item) => {
        if (!filterObj.title || filterObj.title === '' || item.title === filterObj.title) {
          return item;
        }
      });

      tempData = tempData.filter((item) => {
        if (!filterObj.leadstatus || filterObj.leadstatus === '' || item.leadstatus === filterObj.leadstatus) {
          return item;
        }
      });

      setRegFormList(tempData);
    }

  }


  //   const onFilterType = (event) => {
  //     console.log('event.target.value', event.target.value)
  //     if (event.target.value === "") {
  //       setBody(lead);
  //     } else {
  //       setBody(
  //         lead.filter((data) => {
  //           console.log('data',data)
  //           if (
  //             (data.title || "").toLowerCase() ===
  //             (event.target.value || "").toLowerCase()
  //           ) {
  //             return data;
  //           }
  //         })
  //       );
  //     }
  //   };

  const getStatusClass = (status) => {
    let accessStatusRec = leadStatusArray.find((value) => value.label === status);
    console.log('accessStatusRec', accessStatusRec)
    if (accessStatusRec?.className) {
      return accessStatusRec.className
    } else {
      return ''
    }


  };



  // const getTypeClass = (title) =>{
  //   //console.log('status',status)
  //   let accessTypeRec = leadTypeArray.filter((value, index, array) => {
  //     if(value.label === title){

  //      return true
  //     }

  //   });
  //   //console.log('accessStatusRec',accessStatusRec)
  //   if(accessTypeRec && accessTypeRec.length > 0){

  //   if(accessTypeRec[0].is_converted === true){
  //     //console.log('if isconverted')

  //     return 'success';
  //   }else if (accessTypeRec[0].is_lost === true){
  //     return 'secondary';
  //   }else{
  //     return 'primary';
  //   }
  // }else{
  //   return 'secondary';
  // }
  // }

  const pdfRecorder = () => {
    setRefreshList(Date.now());
    setTimeout(function () {
      pdf();
    }, 500)
  };
  const pdf = () => {
    const pdfTable = document.getElementById("divToPrint");
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    const documentDefinition = {
      content: [html],
      pageBreakBefore: function (currentNode) {
        return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      }
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();

  };

  let actionsColumn = {
    title: '', // Provide a default title or an empty string
    prop: '',   // Provide a default prop or an empty string
    cell: (row) => null, // Provide a default cell function that returns null
  };

  if (
    localStorage.getItem("userrole") === "ADMIN" ||
    localStorage.getItem("userrole") === "SUPER_ADMIN"
  ) {
    actionsColumn = {
      title: 'Actions',
      prop: 'id',
      cell: (row) => (
        <>
          <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}>
            <i className="fa-regular fa-trash-can"></i>
          </Button>
        </>
      )
    };
  }
  // Create table headers consisting of 4 columns.
  const header = [];
  if (!isMobile) {
    header.push(
      {
        title: "Lead Unique Id",
        prop: "leaduniqueid",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard">
            <Link to={"/leads/All/" + row.id} state={row}>
              {row.leaduniqueid}
            </Link>
          </div>

        ),
      },


      { title: "First Name", prop: "firstname", isFilterable: true },
      // { title: "Last Name", prop: "lastname", isFilterable: true },
      { title: "Phone", prop: "phone", isFilterable: true },
      //{ title: "Name of Firm", prop: "firm", isFilterable: true },
      { title: "Requirmemt", prop: "description", isFilterable: true },
      { title: "Lead Type", prop: "title", isFilterable: true },
      //{ title: "Location", prop: "loc_land", isFilterable: true },
      { title: "City", prop: "city", isFilterable: true },
      { title: "Other City", prop: "othercity", isFilterable: true },
      { title: "Amount", prop: "amount", isFilterable: true },
      { title: "Date of Visit", prop: "createddate", isFilterable: true, cell: (row) => (moment(row.createddate).format('YYYY-MM-DD')) },
      //{ title: "Email", prop: "email", isFilterable: true },
      //{ title: "Company", prop: "company", isFilterable: true, isSortable: true },

      {
        title: "Lead Status",
        prop: "leadstatus",
        isFilterable: true, isSortable: true,
        cell: (row) => (
        
            <Badge bg={getStatusClass(row.leadstatus)}>
              {row.leadstatus}
            </Badge>
           
        ),
      },

      {

        title: "Marketing Executive Name", prop: "ownername", isFilterable: true, isSortable: true, cell: (row) => (
          <div className="mobilecard">
            <Link to={"/users/" + row.ownerid} state={row}>

              {row.ownername}
            </Link>
          </div>

        ),
      },
      // {
      //   title: 'Actions', prop: 'id', cell: (row) => (
      //     <div className="mobilecard">
      //       <><Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i className="fa-regular fa-trash-can"></i></Button>
      //       </>
      //     </div>

      //   )
      // }
      actionsColumn,
    )
  } else {
    header.push(
      {
        title: "Lead Unique Id",
        prop: "leaduniqueid",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <Link to={"/leads/All/" + row.id} state={row}>
            {row.leaduniqueid}
          </Link>
        ),
      },


      { title: "First Name", prop: "firstname", isFilterable: true },
      // { title: "Last Name", prop: "lastname", isFilterable: true },
      { title: "Phone", prop: "phone", isFilterable: true },
      //{ title: "Name of Firm", prop: "firm", isFilterable: true },
      { title: "Requirmemt", prop: "description", isFilterable: true },
      { title: "Lead Type", prop: "title", isFilterable: true },
      //{ title: "Location", prop: "loc_land", isFilterable: true },
      { title: "City", prop: "city", isFilterable: true },
      { title: "Other City", prop: "othercity", isFilterable: true },
      { title: "Amount", prop: "amount", isFilterable: true },
      { title: "Date of Visit", prop: "createddate", isFilterable: true, cell: (row) => (moment(row.createddate).format('YYYY-MM-DD')) },
      //{ title: "Email", prop: "email", isFilterable: true },
      //{ title: "Company", prop: "company", isFilterable: true, isSortable: true },

      {
        title: "Lead Status",
        prop: "leadstatus",
        isFilterable: true, isSortable: true,
        cell: (row) => ( 
          //{ console.log('row.leadstatus web', row.leadstatus) }
        //  return (
            //for color in status
            <Badge bg={getStatusClass(row.leadstatus)}>
            {row.leadstatus}
          </Badge>
         // );
        ),
      },
      // {
      //   title: "Lead Type",
      //   prop: "title",
      //   isFilterable: true, isSortable: true,
      //   cell: (row) => {
      //     return (
      //       <Badge bg={getTypeClass(row.title)} style={{display: "block", paddingBottom : "5px"}}>
      //         {row.title} 
      //       </Badge>
      //     );
      //   },
      // },
      {

        title: "Marketing Executive Name", prop: "ownername", isFilterable: true, isSortable: true, cell: (row) => (
          <Link to={"/users/" + row.ownerid} state={row}>

            {row.ownername}
          </Link>
        ),
      },
      //{ title: "Lead Source", prop: "leadsource", isFilterable: true },
      //{ title: "City", prop: "city", isFilterable: true },
      actionsColumn,

    )
  }

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createLead = () => {
    navigate(`/leads/e`);
  };

  const ReportDataCSV = {
    data: body,
    filename: 'Report',
    delimiter: ',',
    headers: arrName
  }

  return (
    <div>
      {modalShow &&
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteLead={deleteLead}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="lead"
        />}
      <Row className="g-0">

        <Col lg={12} className="px-4">


          <DatatableWrapper
            body={regFormList}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={6}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <PaginationOptions labels={labels} />
                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter Type"
                    name="title"
                    onChange={onFilterSelection}
                  >
                    <option value="">--Select Type--</option>
                    {leadTypeArray.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    )
                    )}
                  </Form.Select>
                </Form.Group>

                {/* <Form.Group className="mx-3 mt-4" controlId="formBasicServiceCategory">
                                    <Form.Select aria-label="Enter status" name="title" onChange={onFilterSelection}>
                                        <option value="">--Service Area--</option>
                                        <option value="Architects">Architects</option>
                                        <option value="Builder">Builder</option>
                                    </Form.Select>
                                </Form.Group> */}

                {/* <PaginationOptions labels={labels} /> */}
                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter status"
                    name="leadstatus"
                    onChange={onFilterSelection}
                  >
                    <option value="">--Select Type--</option>
                    {leadStatusArray.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                {/* <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                                    <Form.Select aria-label="Enter status" name="leadstatus" onChange={onFilterSelection}>
                                        <option value="">--All Students--</option>
                                        <option value="Contacted">Contacted</option>
                                        <option value="Contacted">Contacted</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Dropout">Dropout</option>
                                    </Form.Select>
                                </Form.Group> */}



                {!isMobile ? (
                  <InfoPill left="Total" right={body?.length} />
                ) : null}
                {/* </Col> */}


                {/* <InfoPill left="Total" right={body?.length} /> */}
                {/* <Button className='btn-sm' variant='danger' onClick={() => pdfRecorder()} s><i className="fa-solid fa-print"></i> PDF</Button> */}


                {/* <Col className="d-flex flex-col justify-content-end align-items-end"></Col> */}

                {!isMobile ? (
                    <div className="d-flex align-items-center mt-3  ">
                      <Button className="btn-sm mr-2" variant="outline-success" onClick={() => csvDownload(ReportDataCSV)}
                      >
                        <i className="fa-solid fa-file-csv" style={{ fontSize: '24px' }}></i></Button></div>
                  ) : null}

                <div style={{ marginLeft: '25px' }} />
                {!isMobile ? (
                    <div className="d-flex align-items-center mt-3">
                      <Button className="btn-sm mr-2" variant="danger" onClick={() => pdfRecorder()}>
                        <i className="fa-solid fa-print"></i> PDF
                      </Button>
                      {/* Other form elements */}
                    </div>
                  ) : null}
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={3}
                className="mt-4 d-flex flex-col justify-content-end align-items-end"
              >
                {(localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "USER") && (
                  <Button
                    className="btn-sm"
                    variant="outline-primary mx-2"
                    onClick={() => createLead(true)}
                  >
                    New Lead
                  </Button>
                )}
                {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}
              </Col>
            </Row>

            <Table striped className="data-table" responsive="sm">
              <TableHeader />

              <TableBody />
            </Table>


            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
        <div className="App container mt-5" style={{ display: 'none' }}>

          <div id="divToPrint">
            <LeadPDF leads={regFormList} refresh={refreshList} />
          </div>
        </div>
      </Row>
    </div>
  );
};
export default LeadList;
