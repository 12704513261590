import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import FilesCreate from "./FilesCreate";
import Confirm from './Confirm';


const RelatedLocationHistory = (props) => {

    const [modalShow, setModalShow] = React.useState(false);
    const [productvarient, setProductvarient] = React.useState('');
    const [modalShowProductvarient, setModalShowProductvarient] = React.useState(false);
    const [modalShowProductvarientView, setModalShowProductvarientView] = React.useState(false);

    const submitProductvarients = () => {
        setModalShowProductvarient(false);
        //productvarientList();
      }
    
      const handleDelete = (row) => {
        setModalShow(true);
        setProductvarient(row);
      }
    
      const deleteProductvarient = async () => {
        const result = await SparkApi.deleteProductvarient(productvarient.id);
        if (result.success) {
          setProductvarient('');
          setModalShow(false);
          //productvarientList();
        }
      }
    
    
      const editProductvarient = (row) => {
        setModalShowProductvarient(true)
        setProductvarient(row.row);
      }
    

    const [body, setBody] = useState([]);
    useEffect(() => {
        async function init() {
            let usertrackings = await SparkApi.fetchUsertrackingsWithstaffId(props.parent.id);
            console.log('usertrackings', usertrackings);
            if (usertrackings && usertrackings?.length > 0) {
                setBody(usertrackings);
            } else {
                setBody([]);
            }
        }
        init();
    }, []);


    const labels = {
        beforeSelect: " "
    }

    const header = [
        {
            title: 'Login Date Time', prop: 'logindatetime', cell: (row) => (moment(row.logindatetime).format('DD-MM-YYYY hh:mm'))
        },
        {
            title: 'Logout Date Time',
            prop: 'logoutdatetime',
            cell: (row) => (row.logoutdatetime ? moment(row.logoutdatetime).format('DD-MM-YYYY hh:mm A ') : '')
        },
        { title: 'Location', prop: 'location' },
        { title: 'Remarks', prop: 'remarks' },
        { title: 'Client Name', prop: 'clientname' },
    ];


    return (
        <>

    {modalShow &&
      <Confirm
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteProductvarient={deleteProductvarient}
        title="Confirm delete?"
        message="You are going to delete the record. Are you sure?"
        table="productvarient"
      />}
      {modalShowProductvarient &&
        <FilesCreate
        show={modalShowProductvarient}
        onHide={() => setModalShowProductvarient(false)}
       // parent={props.parent}
        productvarients={productvarient}
        submitProductvarients={submitProductvarients}
      /> 
        }

            {body ?
                <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 5
                    }
                }}>
                    <Row className="mb-4">
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                        </Col>
                    </Row>
                    <Table striped className="related-list-table" responsive = "sm">
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Pagination />
                </DatatableWrapper> : ''}
        </>
    )
};

export default RelatedLocationHistory
