import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../Auth/AuthProvider";
import jwt_decode from "jwt-decode";
import React from "react";

const Auth = ({ allowedRoles }) => {
  const { auth, setAuth, accessToken } = useContext(AuthContext);
  const location = useLocation();
  let permissionArray = [];
  let accessToken1 =  localStorage.getItem("token")
  if (accessToken) {
    const decodedToken = jwt_decode(accessToken);
    const permissions = decodedToken.permissions;
    permissions &&
      permissions.map((vl) => {
        permissionArray.push(vl.name);
      });
  }else if(accessToken1){
    const decodedToken = jwt_decode(accessToken1);
    const permissions = decodedToken.permissions;
    permissions &&
      permissions.map((vl) => {
        permissionArray.push(vl.name);
      });
  }

  
  return allowedRoles.find((role) => permissionArray.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to={"/no-access"} state={{ from: location }} replace />
  );
};

export default Auth;