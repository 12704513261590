import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";
import * as constants from '../constants/CONSTANT';
import PubSub from "pubsub-js";
import RelatedListBusiness from "./RelatedListBusiness";
import moment from "moment";

const BusinessView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [business, setBusiness] = useState(location.state ? location.state : {});
  //console.log('business', business)
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListBusinesstTests, setRelatedListBusinesstTests] = useState(true);

  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [reletedListBusiness, setRelatedListBusiness] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [userName, setUserName] = useState("");
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshBusinessList, setRefreshBusinessList] = useState();
  const [convertShow, setConvertShow] = useState(true);
  const [businessStatusArray, setbusinessStatusArray] = useState(JSON.parse(localStorage.getItem('business_status')));


  useEffect(() => {
    
    fetchBusiness();
    async function init() {
      const fetchUser = await SparkApi.fetchUsers();
      //console.log("fetchUser=>" + JSON.stringify(fetchUser));
      //console.log("assignrole" + fetchUser.assignrole);
      //console.log("assignrole" + fetchUser.ownerid);
      let usr = [];
      fetchUser.map((item) => {
        if (location.state.assignrole === item.id) {
          setUserName(item.username);
        }
      });
    }

    init();
  }, []);

  const fetchBusiness = () => {
    if (
      !business.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      business.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      let result = await SparkApi.fetchBusinessById(business.id);
      console.log('result business by id', result);
      if (result?.convertedcontactid) {
        //console.log('convert call');
        setConvertShow(false);

      }
      if (result) {
        //console.log("=======", result);

        setBusiness(result);
        setData(result);
        //console.log("name : ", business.firstname);
      } else {
        setBusiness({});
      }
    }
    initStudent();
  };
  const deleteBusiness = async () => {
    const result = await SparkApi.deleteBusiness(business.id);
    if (result.success) navigate(`/business`);
    //console.log("result.success : ", result.success);
  };
  const editBusiness = () => {
    navigate(`/business/${business.id}/e`, { state: business });
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
      setRelatedListBusiness(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
      setRelatedListBusiness(false)
    }else if (key === "business") {
      setRelatedListBusiness(true);
      setReletedListTask(false);
      setRelatedListFiles(false);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };


  const handleConvert = async () => {
    let convertStatus = '';

    let status = businessStatusArray.filter(item => item.is_converted === true)
    console.log('status:', status);
    convertStatus = status[0].label;
    const result = await SparkApi.convertBusinessToContact(business.id, convertStatus);
    //console.log('result', result)
    if (result) {
      let contactId = result.convertedcontactid
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Business Convert ",
        message: "Record Convert successfully",
      });
      navigate(`/contacts/${contactId}`)
      //console.log("result.success : ", result.success);
    }

  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  return (
    <div>
      {business && (
        <Container>
           <Row className="view-form">
         
         <Col lg={11} className="pb-3 pt-2">
       <Link className="nav-link" to="/business">
         Home <i className="fa-solid fa-chevron-right"></i> <div style={{color: "#23468c", display : "inline"}}>Business</div>
       </Link>
     </Col>
     <Col></Col>
           </Row>
          <Row className="view-form">
            <Col></Col>
            <Col lg={11}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Business
                  <h5>
                    {business.name}
                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editBusiness(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  {/* <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button> */}
                 
                </Col>
              </Row>

              <Row className="py-3 ibs-edit-form">
                <Col lg={12}>
                  <Path values={businessStatusArray} selectedValue={business.businessstatus} />
                </Col>


                <Col lg={6}>
                  <label>Business Name</label>
                  <span>
                    {business.name}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Amount</label>
                  <span>₹ {business.amount}</span>
                </Col>
                <Col lg={6}>
                  <label>Payment Model</label>
                  <span>{business.paymentmodel}</span>
                </Col>
                <Col lg={6}>
                  <label>Payment Terms</label>
                  <span>{business.paymentterms}</span>
                </Col>
                <Col lg={6}>
                  <label>Contact</label>
                 <span> <Link to={"/contacts/" + business.contactid}>
        {business.contactname}
      </Link></span>
                </Col>
                <Col lg={6}>
                  <label>Account</label>
                  <span> <Link to={"/accounts/" + business.accountid}>
        {business.accountname}
      </Link></span>
                 
                </Col>
                <Col lg={6}>
                  <label>Lead Source</label>
                  <span>{business.leadsource}</span>
                </Col>

         

                <Col lg={6}>
                  <label>Industry</label>
                  <span>{business.industry}</span>
                </Col>
                
               
                <Col lg={6}>
                  <label>Owner</label>
                  <span><Badge bg="warning" style={{display:"inline", color: "#000"}}>{business.ownername}</Badge></span>
                </Col>
                <Col lg={6}>
                  <label>Created Date</label>
                  <span>{moment(business.createddate).format('DD/MM/YYYY')}</span>
                </Col>
                <Col lg={12}>
                  <label>Description</label>
                  <span>{business.description}</span>
                </Col>
                
                
            <Col></Col>
          </Row>


          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={business}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}

                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={business?.id}


                  table="business"
                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={business}
                />
              )}
              
              
            </Card.Body>
          </Card>







</Col>
<Col></Col>
</Row>

        </Container>
      )}
    </div>
  );
};
export default BusinessView;
