import React, { useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import PubSub from 'pubsub-js';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { async } from "q";
import SparkApi from "../api/SparkApi";


const ChangePassword = ({ userId }) => {
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState({password: '', confirmpassword: ''});
    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        //console.log('userId', userId);
        //console.log('user', user)
        if (user.password === user.confirmpassword && user.password !== '') {
            const result = await SparkApi.updateUser(user);
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Password updated successfully' });
            //console.log(result);
        } else {
            //console.log('Password is null, please enter a valid password.');
        }
    };

    return (
        <div>
            <Container className="view-form">
                <Row>
                    <Col></Col>
                    <Col lg={8}>
                        <Form className="mt-3" onSubmit={handleSubmit} validated={validated}>
                            <Row className="view-form-header align-items-center ">
                                <Col lg={3}>
                                    Change Password
                                </Col>
                                <Col lg={9} className="d-flex justify-content-end">
                                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                        Update
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="ibs-edit-form pb-3">
                                <Col lg={12}>
                                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicFirstName"
                                        >
                                          New Password
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Enter Your password"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                           
                          
                                <Col lg={12}>
                                    <Form.Group className="mx-3" controlId="formBasicPhone">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicPhone"
                                        >
                                            Confirm Password
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="confirmpassword"
                                            placeholder="Enter confirm password"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                           
                        </Form>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChangePassword;
