import { useState } from 'react';
import * as constants from '../constants/CONSTANT';
const authApi = {
    async login(email, password) {
        //console.log('constants.API_BASE_URL', process.env.REACT_APP_API_BASE_URL)
        let response = await fetch(constants.API_BASE_URL + "/api/auth/login", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const result = await response.json();
        if (result.success) {
            //console.log("login" + JSON.stringify(result));
            //console.log("Authtoken" + JSON.stringify(result.authToken));
            // //console.log("Authtoken"+JSON.stringify(result.authToken));
            localStorage.setItem("token", result.authToken);
            localStorage.setItem("username", result.username);
            localStorage.setItem("userrole", result.userrole);
            localStorage.setItem("companyname", result.companyname);
            localStorage.setItem("logourl", result.logourl);
            localStorage.setItem("sidebarbgurl", result.sidebarbgurl);


            //console.log("result.userrole" + JSON.stringify(result.userrole));

            const arrayPermissions = [{ name: 'MODIFY_ALL' }, { name: 'VIEW_PRODUCT' }, { name: 'VIEW_CONTACT' }, { name: 'VIEW_ORDER' }, { name: 'VIEW_LEAD' }]
            var string = result.permissions?.map(function (obj) {
                return obj.name;
            }).join(';')
            localStorage.setItem("permissions", string);
        }
        return result;
    },

    async fetchMyImage() {
        //console.log("calling my image ");
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/auth/myimage",
          {
            method: "GET",
            //mode: "cors",
    
            headers: {
              "Authorization": token
            }
          }
        );
        //console.log('response:', response);
        if(response.status === 200){
          const fileBody = await response.blob();
          return fileBody;
        }else{
          return null;
        }
        
      },

      async fetchUserImage(userid) {
        //console.log("calling my image ");
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/auth/userimage/" + userid,
          {
            method: "GET",
            //mode: "cors",
    
            headers: {
              "Authorization": token
            }
          }
        );
        //console.log('response:', response);
        const fileBody = await response.blob();
        return fileBody;
      },

    logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("permissions");
        window.location.href = '/login';
    },

    async bs() {
        //console.log("sssss");
        return "yes";
    },

}

export default authApi
