import React, { useEffect, useState } from "react";
import SparkApi from "../api/SparkApi";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import LeadList from "./LeadList";
import { Table } from "react-bootstrap";

const LeadPDF = (props) => {

    const [leadtest, setLeadtest] = useState(props.leads ? props.leads : []);
    // const[leadinfo, setLeadinfo] = useState([]);

    useEffect(() => {
        console.log('props.lead', props.leads)
        if (props?.leads) {
            setLeadtest(props.leads);
            //fetchLeadtest();
        }
    }, [props.refresh]);

   

    return (

        <div style={{fontSize:'8px'}}>
            <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Lead Unique No.</th>
                    <th>First Name</th>
                    {/* <th>Last Name</th> */}
                    <th>Phone</th>
                    <th>Requirment</th>
                    <th>Lead Type</th>
                    <th>City</th>
                    <th>Other City</th>
                    <th>Amount</th>
                    <th>Date of Visit</th>
                    <th>Lead Status</th>
                    <th>Marketing Exwcutive Name</th>


                </tr>
            </thead>
            <tbody>
                {leadtest.map((data, index) => (
                    <tr key={index}>
                        <td>{data.leaduniqueid}</td>
                        <td>{data.firstname}</td>
                         {/* <td>{data.lastname}</td>  */}
                        <td>{data.phone}</td>
                        <td>{data.description}</td>
                        <td>{data.title}</td>
                        <td>{data.city}</td>
                        <td>{data.othercity}</td>
                        <td>{data.amount}</td>
                        <td>{data.createddate}</td>
                        <td>{data.leadstatus}</td>
                        <td>{data.ownername}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        </div>

    );
};
export default LeadPDF;
