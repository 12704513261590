import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Container, Form, Spinner, Row, Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { Shimmer } from "react-shimmer";
import Image from 'react-bootstrap/Image'
import Card from "react-bootstrap/Card";
import PubSub from 'pubsub-js';


const UserTracking = (props) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef();
  const [body, setBody] = useState();
  const [body2, setBody2] = useState();
  const [selectedFiles, setSelectedFiles] = useState();
  const [selectedFiles2, setSelectedFiles2] = useState();
  const [thumbnail, setThumbnail] = useState(null);
  const location = useLocation();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [remarks, setRemarks] = useState();
  const [clientname, setClientname] = useState();
  const [result, setResult] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [locationRecord, setLocationRecord] = useState({});
  const [currentrecord, setCurrentrecord] = useState();
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [address, setAddress] = useState();
  const [parent, setParent] = useState()
  const [userId, setUserId] = useState(null);




  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        // setAlert(null);
      }, 3000)
    }
  }, [alert])

  useEffect(() => {
    async function init() {
      let result = await SparkApi.getLoginUserData();
      setParent(result);
      console.log("parent", result)


    }

    init();

    /*//console.log('currentrecord use effecct =',);
    currentrecord && Promise.resolve(currentrecord).then((record) => {
        //console.log('record use effecct =',record);
        if(record && record.hasOwnProperty('errors')){
            setButtonDisabled(false)
        } else if(record.logindatetime != null && record.logoutdatetime == null){
            setResult(record)
            setRemarks(record.remarks)
            setButtonDisabled(true)
        } else if (record.logindatetime.length && record.logoutdatetime.length){
            setResult(record)
            setRemarks('')
            setButtonDisabled(false)
        }
    })*/
  }, [])

  useEffect(() => {
    async function init() {
      let result = await SparkApi.getLoginUserData();
      //setProfile(result);
      setBody(localStorage.getItem("myimage"));
      setBody2(localStorage.getItem("myimage"));
      setParent(result);
      setUserId(result?.id); // Assuming the ID property is `id`

      let userdata = await SparkApi.getCurrentUserTrackingRec();
      //console.log('userdata:', userdata);
      setCurrentrecord(userdata);
      setLocationRecord(userdata);
      if (userdata.logindatetime != null && userdata.logoutdatetime == null) {
        setButtonDisabled(true)
        setRemarks(userdata.remarks)
        setClientname(userdata.clientname)
      } else if (userdata?.logindatetime?.length && userdata?.logoutdatetime?.length) {
        setRemarks(userdata.remarks)
        setClientname(userdata.clientname)
        setButtonDisabled(false)
      }
    }
    init();

    //setSpinner(true);
    if (!window.myMap) {
      console.log('!window.myMap')
      window.myMap = myMap;
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBZsH0US1O6fSogoqBTUpUkvEWqs-rYMlY&callback=myMap`;
      // console.log('refresh:1');
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      // console.log('refresh:2');
    } else {
      console.log("window.myMap000")
      myMap();
    }

    //setCurrentrecord({errors:'No Data'});
    ////console.log('currentRec => ', currentRec);
  }, []);

  const handleSubmit = async (event) => {
    //event.preventDefault();
    let result = await SparkApi.saveStaffMemberEditProfile(selectedFiles);
    localStorage.setItem('myimage', body, body2);
    //console.log('file update', result);
    //PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });

  }


  const handleVisitUpload = (event) => {
    setBody(URL.createObjectURL(event.target.files[0]));
    setSelectedFiles(event.target.files[0]);
  };

  const handleSiteUpload = (event) => {
    setBody2(URL.createObjectURL(event.target.files[0]));
    setSelectedFiles2(event.target.files[0]);
  };

  const myMap = async () => {
    //console.log('calling my map=====')



    var mapProp = {
      center: new window.google.maps.LatLng(latitude ? latitude : 12, longitude ? longitude : 12),
      zoom: 14,
    };

    const locate = window.navigator && window.navigator.geolocation;
    const currentCoords = { lat: 0, lng: 0 };

    if (locate) {
      console.log('in locate==>')
      locate.getCurrentPosition((position) => {
        console.log('in position', position)
        currentCoords.lat = position.coords.latitude;
        currentCoords.lng = position.coords.longitude;
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);

        // setSpinner(false)
        mapProp = {
          center: new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude),
          zoom: 14,
        }

        var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);
        //console.log('location => ',location)
        var geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: currentCoords }, function (results, status) {
          //console.log(results)
          let cur_address = results && results.length > 0 ? results[0].formatted_address : '';
          setAddress(cur_address);
          var marker = new window.google.maps.Marker({
            map: map,
            position: { lat: currentCoords.lat, lng: currentCoords.lng },
            title: cur_address,
            content: cur_address,
          });

          var infowindow = new window.google.maps.InfoWindow({
            content: cur_address
          });
          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              map,
            });
          });

        });

      }, (error) => {
        //console.log('error in location -> ', error) 
      }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      )
    }

    setTimeout(() => {
      //console.log('mapProp => ', mapProp)


    }, 500)

  }



  const setLatLongJs = async (checkIn) => {
    //console.log("object")
    const locate = window.navigator && window.navigator.geolocation;
    //console.log("9999",locate)
    if (locate) {
      //console.log("9999",locate)
      //console.log('in locate==>');
      //console.log('lat lon log => ', latitude + ' ' + longitude);
      locate.getCurrentPosition(async (position) => {
        // console.log('in position', position);    
        // console.log('HI', checkIn)
        if (checkIn) {

          let res = await SparkApi.createCheckInRecord({ 'logindatetime': new Date(), loginlattitude: position.coords.latitude.toString(), loginlongitude: position.coords.longitude.toString(), remarks, location: address, parentid: parent.id });
          // let res = await SparkApi.createCheckInRecord({ 'logindatetime': new Date(), loginlattitude: position.coords.latitude.toString(), loginlongitude: position.coords.longitude.toString(), remarks, location: address, parentid: userId });


          setTimeout(() => {

            if (res) {
              setButtonDisabled(true);
              setLocationRecord(res);
              setAlert({ message: 'You have been Successfully Checked In.', type: 'success' });
            } else {
              setAlert({ message: 'Somthing Went Wrong.', type: 'danger' });
            }
          }, '500')

        } else {
          //console.log('false',result);
          if (remarks && locationRecord && parent && parent.id && clientname) {
            let res;
            //console.log('locationRecord:', locationRecord);

            res = await SparkApi.handlCheckOut({ ...locationRecord, 'logoutdatetime': new Date(), 'logoutlattitude': position.coords.latitude.toString(), 'logoutlongitude': position.coords.longitude.toString(), 'remarks': remarks, 'clientname': clientname })
            //console.log('res:', res);
            setTimeout(() => {
              setRemarks("");
              setClientname("");

              if (res) {
                setButtonDisabled(false);
                setAlert({ message: 'You have been Successfully Checked out.', type: 'danger' })
              } else {
                setAlert({ message: 'Somthing Went Wrong.', type: 'danger' })
              }
            }, '500');
          } 
          // else {
          //   setAlert({ message: 'Please fill in all the required fields.', type: 'danger' });
          // }
          else {
            let missingFields = [];
            if (!clientname) {
              missingFields.push('Client Name');
            }
            if (!remarks) {
              missingFields.push('Remarks');
            }
            let message = `Please fill in the following required fields: ${missingFields.join(', ')}.`;
            setAlert({ message, type: 'danger' });
          }
        }
      }, (error) => {
        //console.log('error in location -> ', error) 
      })
    }
  }

  const handleCheckIn = async () => {
    //event.preventDefault();
    const token = localStorage.getItem("token");
    let current = new Date();
    var formData = new FormData();
console.log('selectedFiles',selectedFiles)
   
      formData.append(`files1`, selectedFiles);
      formData.append(`files2`, selectedFiles2);
    console.log('formData', formData,userId)
    // const result = await SparkApi.createFile(props.parent.id, formData);
    const result = await SparkApi.createFile(userId, formData);
    //console.log('result',result)
    if (result) {
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
      //submitFiles();
    }
    //console.log('hellooo')
    setLocationRecord({});
    setLatLongJs(true);
    // setFiles(event.target.files);
  }

  //   const submitFiles = () => {
  //     submitFiles();
  // };


  const handleCheckOut = async () => {
     //event.preventDefault();
     const token = localStorage.getItem("token");
     let current = new Date();
     var formData = new FormData();
 console.log('selectedFiles',selectedFiles)
    
       formData.append(`files1`, selectedFiles);
       formData.append(`files2`, selectedFiles2);
     console.log('formData', formData,userId)
     // const result = await SparkApi.createFile(props.parent.id, formData);
     const result = await SparkApi.createFile(userId, formData);
     //console.log('result',result)
    //  if (result) {
    //    PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
    //    //submitFiles();
    //  }
     //console.log('hellooo')
     setLocationRecord({});
 
    setLatLongJs(false);

  }

  const handleremarkChange = (e) => {
    //console.log(e.target.value)
    setRemarks(e.target.value);
  }

  const handleclientChange = (e) => {
    //console.log(e.target.value)
    setClientname(e.target.value);
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  }

  return (
    <div className='p-4'>
      <Container >
        <Row className="view-form">
          <Col lg={8}>

            <Row className="">
              {alert && <Alert variant={alert.type} onClose={() => setAlert(false)} style={{ width: '100%', padding: '15px', margin: "0px 0px 5px 0px", fontWeight: 'bold', textAlign: 'center' }}>
                {alert.message}
              </Alert>}
              {spinner && <Shimmer height={500} ></Shimmer>}
              {!spinner && <div id="googleMap" style={{ width: '100%', height: '300px', border: '1px solid black' }}>

              </div>}
            </Row>
            <Row>
              <Form.Group className="" style={{ padding: '0' }} controlId="formAddress">
                <Form.Label className="form-view-label" htmlFor="formAddress">Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  value={address}
                  onChange={handleAddressChange}
                />

              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="" style={{ padding: '0' }} controlId="formBasicFirstName">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicFirstName"
                >
                  Remarks
                </Form.Label>
                <Form.Control
                required={true}
                  as="textarea"
                  name="remarks"
                  value={remarks}
                  placeholder="Enter remarks.."
                  onChange={handleremarkChange}
                />
                {/* {errors.name && touched.name ? (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.name}
                    </p>    
                  ) : null} */}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="" style={{ padding: '0' }} controlId="formBasicFirstName">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicFirstName"
                >
                  Client Name
                </Form.Label>
                <Form.Control
                required={true}
                  type="text"
                  name="clientname"
                  value={clientname}
                  placeholder="Enter Client Name.."
                  onChange={handleclientChange}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Visiting Card Pic</Form.Label>
                <Form.Control type="file" multiple onChange={handleVisitUpload} />
                <Image variant="top"
                  src={body}
                  //className="rounded-circle"
                  thumbnail
                  style={{ width: "150px" }}></Image>
                {/* <Card>
                          <Card.Body className="text-center">
                            
                            <Image variant="top"
                              src={body}
                              //className="rounded-circle"
                              thumbnail
                              style={{ width: "100px" }}></Image>
                            <br />
                            
                           
                           
                          </Card.Body>
                        </Card> */}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Location Pic</Form.Label>
                <Form.Control type="file" multiple onChange={handleSiteUpload} />
                <Image variant="top"
                  src={body2}
                  //className="rounded-circle"
                  thumbnail
                  style={{ width: "150px" }}></Image>
              </Form.Group>
            </Row>

            <Row className="my-3">
              <Col>
                <Button
                  disabled={buttonDisabled}
                  variant='success'
                  //type="file"
                  onClick={() => handleCheckIn()}
                >
                  Check-In
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={!buttonDisabled}
                  variant='danger'
                  onClick={() => handleCheckOut()}
                >
                  Check-Out
                </Button>
              </Col>
            </Row>

          </Col>
          <Col lg-4>

          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserTracking;