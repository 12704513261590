import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import * as constants from '../constants/CONSTANT';

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);
  const location = useLocation();

  const hideSideBar = () => {
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
  }

  return (
    <>
      {/* <nav id="sidebar" className='show' style={{ fontSize: "small", backgroundImage: `url(${localStorage.getItem('sidebarbgurl')})` }}> */}
      <nav id="sidebar" className='show' style={{ fontSize: "small" }}>
        <div>
          <div className="sidebar-header" style={{ padding: "2rem 2rem 2rem 1rem", display: "flex", justifyContent: "center" }}>
            <img src="/logoEECO.png" style={{ width: "65%" }} />
          </div>

          <ul className="list-unstyled components">
            <li className={`${location.pathname === '/' ? 'active' : ''}`} onClick={hideSideBar}>

              <Link to="/" /*className="dropdown-toggle"*/ style={{ borderTop: "1px solid #fff" }}> <i className="fa-solid fa-house mx-2" ></i> Home</Link>

            </li>
            <li >
              {/* <Link to="/"> <i className="fa-solid fa-chart-simple mx-2"></i> Accounts</Link> */}
            </li>
            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_ACCOUNT) >= 0 || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/accounts') ? 'active' : ''}`}>
                <Link to="/accounts"> <i className="fa-solid fa-building mx-2"></i> Accounts</Link>
              </li>
              : ''} */}

            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_PRODUCT) >= 0 
                                                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/products"> <i className="fa-solid fa-chart-simple mx-2"> </i> Products</Link>
                </li>
                : ''} */}
            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
              || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/contacts') ? 'active' : ''}`}>
                <Link to="/contacts"> <i className="fa-solid fa-address-book mx-2"></i> Contacts</Link>
              </li> : ''} */}

            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_BUSINESS) >= 0
              || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/business') ? 'active' : ''}`}>
                <Link to="/business"> <i class="fa-solid fa-coins mx-2"></i> Business</Link>
              </li> : ''} */}

            {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_LEAD) >= 0
              || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/leads') ? 'active' : ''}`} onClick={hideSideBar}>
                <Link to="/leads/All"> <i className="fa-solid fa-bolt mx-2"></i> Leads</Link>
              </li> : ''}


            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/products"><i className="fa-solid fa-user-md mx-2"></i> Test Master</Link>
                </li>: ''}
                {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/medicaltests"><i className="fa-solid fa-hospital mx-2"></i> Medical Test</Link>
                </li>: ''} */}

            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
              || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/meetings') ? 'active' : ''}`}>
                <Link to="/meetings"> <i className="fa-solid fa-calendar-days mx-2"> </i> Meetings</Link>
              </li> : ''} */}



            {localStorage.getItem("userrole") === "SUPER_ADMIN" && localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/users') ? 'active' : ''}`} onClick={hideSideBar}>
                <Link to="/users"> <i class="fa-solid fa-user mx-2"></i>  Users</Link>
              </li> : ''}

            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
              <li className={`${location.pathname.includes('/usertracking') ? 'active' : ''}`} onClick={hideSideBar}>
                <Link to="/usertracking"> <i class="fa-solid fa-user mx-2"></i>  Check In / Out</Link>
              </li> 
               {/* : ''} */}

            {/* {localStorage.getItem('userrole') && (localStorage.getItem('userrole').indexOf('USER') >= 0 )?
                  <li className={`${location.pathname.includes('/usertracking') ? 'active' : ''}`}>
                  <Link to="/usertracking"> <i class="fa-solid fa-user mx-2"></i>  Check In / Out</Link>
                  </li>: ''} */}

            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0 
            {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/users') ? 'active' : ''}`}>
                <Link to="/users"> <i class="fa-solid fa-user mx-2"></i>  Users</Link>
              </li> : ''}
            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/commissions"><i className="fa-solid fa-percent mx-2"></i>Commission Master</Link>
                </li>: ''}

                {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/packages"><i className="fa-solid fa-fort-awesome mx-2"></i>Package</Link>
                </li>: ''} */}

            {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/backup') ? 'active' : ''}`} onClick={hideSideBar}>
                <Link to="/backup"> <i class="fa-solid fa-database mx-2"></i> Backup</Link>
              </li> : ''}



            {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
              || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/reports') ? 'active' : ''}`} onClick={hideSideBar}>
                <Link to="/reports"> <i className="fa-solid fa-chart-simple mx-2"> </i> Reports</Link>
              </li> : ''}

            <li className={`${location.pathname.includes('/myprofile') ? 'active' : ''}`} onClick={hideSideBar}>
              <Link to="/myprofile"> <i className="fa fa-user-circle mx-2"></i>My Profile</Link>
            </li>

            {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
              || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/changepassword') ? 'active' : ''}`}>
                <Link to="/changepassword"><i className="fa-solid fa-key mx-2"></i>Change Password</Link>
              </li> : ''} */}



          </ul>
          <div className="sidebar-header" style={{ padding: "2rem 2rem 2rem 1rem", borderTop: "1px solid #fff", textAlign: "center" }}>
            {/* {localStorage.getItem("logourl") ? <img src={localStorage.getItem("logourl")} style={{ width: "150px" }} /> : ''} */}
          </div>
        </div>
      </nav>
    </>
  )
}

export default Sidebar
