import logo from "./logo.svg";

import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { AccordionCollapse, Container } from "react-bootstrap";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';

import LeadList from "./components/LeadList";
import LeadView from "./components/LeadView";
import LeadEdit from "./components/LeadEdit";

import AccountList from "./components/AccountList";
import AccountView from "./components/AccountView";
import AccountEdit from "./components/AccountEdit";
import ContactList from "./components/ContactList";
import ContactView from "./components/ContactView";
import ContactEdit from "./components/ContactEdit";
import ContactCreate from "./components/ContactCreate";

import MiniSidebar from "./components/MiniSidebar";
import * as constants from './constants/CONSTANT';
import ChangePassword from "./components/ChangePassword";
import ReportView from "./components/ReportView";
import ReportList from "./components/ReportList";
import Calendar from "./components/Calendar";

import PubSub from 'pubsub-js';
import { Toast, ToastContainer } from "react-bootstrap";
import UserList from "./components/UserList";
import UserEdit from "./components/UserEdit";
import UserView from "./components/UserView";
import EditProfile from "./components/EditProfile";
import UserTracking from "./components/UserTracking";
import BusinessList from "./components/BusinessList";
import BusinessView from "./components/BusinessView";
import BusinessEdit from "./components/BusinessEdit";
import Notification from "./components/Notification";
import BackupList from "./components/BackupList";

import Auth from "./components/Auth/Auth";//Added by Farhan Khan on 08-May-2024 This is Using for Auth Component Call.





function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');

  const mySubscriber = (msg, data) => {

    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        //console.log('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        //console.log('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        //console.log('mytopic: default'); // add your desired log message
        break;
    }
  };
  useEffect(() => {
    //dispatch(fetchAccounts());
    PubSub.subscribe('RECORD_SAVED_TOAST', mySubscriber);
    PubSub.subscribe('RECORD_ERROR_TOAST', mySubscriber);
  }, []);

  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>

          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
            :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
          }

          <Toast.Body>{message instanceof Array ? message.map((item) => {
            return <span>{item.msg}</span>
          }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Home />
                  </div>
                </div>
              </>
            }
          />
          {/******** Show All Leadss *******/}
          <Route
            path="/leads/:status"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadList />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_LEAD) >= 0)?
                      <LeadList /> : 'You have no permission to access account'} */}
                  </div>
                </div>
              </>
            }
          />
          {/******** Show an Lead By Id *******/}
          <Route
            path="leads/All/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadView />
                  </div>
                </div>
              </>
            }
          />

<Route
            path="/maintanance"
            element={
              <>
                <div className="wrapper">
               
                  <div id="content">
                   
                    <Notification />
                  </div>
                </div>
              </>
            }
          />

            

          {/******** Create Lead  *******/}
         
          <Route
            path="leads/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                 
                      {(localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "USER" ) ? 
                    <LeadEdit /> :  <Alert variant='danger' className="alert error-alert text-center pt-3 px-10">
                    <i className="fa-solid fa-triangle-exclamation mx-2"></i>
                    You have no permission to edit Lead
                  </Alert>}
                     </div>
                </div>
              </>
            }
          />
          {/******** Update Lead  *******/}
          <Route
            path="leads/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {(localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "USER"  ) ? 
                    <LeadEdit /> : <Alert variant='danger' className="alert error-alert text-center pt-3 px-10">
                    <i className="fa-solid fa-triangle-exclamation mx-2"></i>
                    You have no permission to edit Lead
                  </Alert>}
                   
                  </div>
                </div>
              </>
            }
          />


          {/**** Business Section */}

          <Route
            path="/business"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BusinessList />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_LEAD) >= 0)?
                      <LeadList /> : 'You have no permission to access account'} */}
                  </div>
                </div>
              </>
            }
          />
          {/******** Show an Lead By Id *******/}
          <Route
            path="business/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BusinessView />
                  </div>
                </div>
              </>
            }
          />

          {/******** Create Lead  *******/}
          <Route
            path="business/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />
          {/******** Update Lead  *******/}
          <Route
            path="business/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BusinessEdit />
                  </div>
                </div>
              </>
            }
          />

          {/* *** Users Section */}
          {/* User Permisson Updated By Farhan Khan on 08-May-2024 for Mutiple Refresh Issue Solve */}
          <Route element={<Auth allowedRoles={[constants.MODIFY_ALL]} />}>
            <Route path={"/users"} element={
            <>
            <div className="wrapper">
              <Sidebar />
              <div id="content">
                <Header />
                  <UserList />
                  
              </div>
            </div>
          </>
            } />
            
          </Route>
          {/* User Permisson Updated By Farhan Khan on 08-May-2024 for Mutiple Refresh Issue Solve  End Here */}

          <Route
            path="/meetings"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Calendar/>
                  </div>
                </div>
              </>
            }
          />

<Route
            path="/meetings/:today"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Calendar/>
                  </div>
                </div>
              </>
            }
          />


          {/******** Edit Profile *******/}
          <Route
            path="/myprofile"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <EditProfile/>
                  </div>
                </div>
              </>
            }
          />


          {/*  Create Lead  */}

          <Route
            path="users/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {(localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "USER"  ) ? 
                     <UserEdit /> : <Alert variant='danger' className="alert error-alert text-center pt-3 px-10">
                    <i className="fa-solid fa-triangle-exclamation mx-2"></i>
                    You have no permission to access User
                  </Alert>}
                   
                  </div>
                </div>
              </>
            }
          />
          {/******** Update Users  *******/}
          <Route
            path="users/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {(localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "USER"  ) ? 
                     <UserEdit /> : <Alert variant='danger' className="alert error-alert text-center pt-3 px-10">
                    <i className="fa-solid fa-triangle-exclamation mx-2"></i>
                    You have no permission to access User
                  </Alert>}
                  </div>
                </div>
              </>
            }
          />


          {/******** Show an User By Id *******/}
          <Route
            path="users/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserView />
                  </div>
                </div>
              </>
            }
          />

          {/******** Show an Lead By Id *******/}
          <Route
            path="users/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadView />
                  </div>
                </div>
              </>
            }
          />

          {/******** Create Lead  *******/}
          <Route
            path="users/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />
          {/******** Update Lead  *******/}
          <Route
            path="users/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />
          {/******** Creat Account *******/}
          <Route
            path="/accounts/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <AccountEdit /> : 'You have no permission to access account'}
                  </div>
                </div>
              </>
            }
          />
          {/******** Show All Accounts *******/}
          <Route
            path="/accounts"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />


                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_ACCOUNT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <AccountList />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}

                  </div>
                </div>
              </>
            }
          />


          {/******** Show an Account By Id *******/}
          <Route
            path="accounts/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <AccountView />
                  </div>
                </div>
              </>
            }
          />
          {/******** Edit Account By Id *******/}
          <Route
            path="accounts/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AccountEdit />
                  </div>
                </div>
              </>
            }
          />

          {/******** Crate a Contact *******/}
          <Route
            path="/contacts/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ContactEdit /> : 'You have no permission to access account'}
                  </div>
                </div>
              </>
            }
          />
          {/******** Show All Contacts *******/}
          <Route
            path="/contacts"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ContactList /> : 'You have No permission for contact'}
                  </div>
                </div>
              </>
            }
          />
          {/******** Show a Contact By Id *******/}
          <Route
            path="contacts/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactView />
                  </div>
                </div>
              </>
            }
          />
          {/******** Edit Contact By Id *******/}
          <Route
            path="contacts/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/changepassword"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ChangePassword />
                  </div>
                </div>
              </>
            }
          />
          <Route path="reports/:id" element={
            <>
              <div className="wrapper">
                <Sidebar />
                <div id="content">
                  <Header />
                  <ReportView />
                </div>
              </div>
            </>
          }
          />
          <Route
            path="/reports"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ReportList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/transactions"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                  </div>
                </div>
              </>
            }
          />

<Route
            path="/usertracking"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserTracking />
                  </div>
                </div>
              </>
            }
          />

                {/* ************Backup ********************/}

                

<Route
            path="/backup"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BackupList />
                  </div>
                </div>
              </>
            }
          />

        </Routes>
      </Router>
    </>
  );
}

export default App;
