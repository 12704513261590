
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom'
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";

// import { ExportJsonCsv } from 'react-export-json-csv';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import moment from "moment";
import csvDownload from 'json-to-csv-export'
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import ReportPDF from './ReportPDF'


const ReportView = () => {
  const [arrName, setArrName] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const report = location.state;
  const [body, setBody] = useState([]);
  const [tempBody, setTempBody] = useState([]);
  const [refreshList, setRefreshList] = useState(Date.now());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fields, setFields] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    async function init() {
      //console.log(report)
      //console.log('reports',report.id)
      const result = await SparkApi.fetchReportsById(report);
      //console.log("api response", result);

      if (result && result.length) {
        setArrName(Object.keys(result[0]));
        setBody(result);
        setTempBody(result);
        console.log(Object.keys(result[0]))
        if (report && report.summarymethod && report.summaryfield) {
          //let totamount = 0;
          const methods = report.summarymethod.split(", ");
          const fieldArry = report.summaryfield.split(", ");
          //console.log('result',fieldArry)
          let obj = {};
          for (let field of fieldArry) {
            obj[field] = 0;
            for (let data of result) {
              for (let mth of methods) {
                if (mth === 'sum') {
                  obj[field] = obj[field] + Number(data[field])
                } else if (mth === 'count') {
                  obj[field] = obj[field] + 1;
                }
              }
            }
          }
          console.log(fieldArry, obj)
          setFields(fieldArry);
          setValues(obj);
          //console.log('totamount',totamount)
          //setTotalAmount(totamount);
        }
      } else {
        setBody([]);
        setTempBody([]);
        setArrName([]);
      }
    }

    init()
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const pdfRecorder = () => {
    setRefreshList(Date.now());
    setTimeout(function () {
      pdf();
    }, 500)
  };
  const pdf = () => {
    const pdfTable = document.getElementById("divToPrint2");
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    const documentDefinition = {
      content: [html],
      pageBreakBefore: function (currentNode) {
        return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      }
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();

  };

  const header = arrName.map((name) => ({
    title: name.charAt(0).toUpperCase() + name.slice(1),
    prop: name,
    isFilterable: true,
  }));

  const labels = {
    beforeSelect: " "
  }
  //console.log(arrName)
  const ReportDataCSV = {
    data: body,
    filename: 'Report',
    delimiter: ',',
    headers: arrName
  }

  // const filterRecordsByDate = () => {
  //   console.log('startDate:', startDate);
  //   console.log('endDate:', endDate);
  //   console.log('body:', tempBody);

  //   if (startDate && endDate && tempBody.length > 0) {
  //     // Log the format of the first record's Visit_Date field
  //     // console.log('First record Visit_Date format:', moment(body[0].Visit_Date).format('YYYY-MM-DD'));

  //     const filteredRecords = tempBody.filter
  //     //(record) => 
  //     //{
  //     // console.log('record.Visit_Date', record.Visit_Date)
  //     // const visitDate = moment(record.Visit_Date, 'DD-MM-YYYY').toDate();

  //     // const start = moment(startDate).toDate();
  //     // const end = moment(endDate).toDate();
  //     // console.log('visitDate:', visitDate);
  //     // console.log('startDate:', startDate);
  //     // console.log('endDate:', endDate);
  //     // console.log('visitDate >= start && visitDate <= end:', visitDate >= start && visitDate <= end);

  //     // return visitDate >= start && visitDate <= end;
  //     // });
  //     //console.log('filteredRecords:', filteredRecords);
  //     if (report && report.summarymethod && report.summaryfield) {
  //       //let totamount = 0;
  //       const methods = report.summarymethod.split(", ");
  //       const fieldArry = report.summaryfield.split(", ");
  //       //console.log('result',fieldArry)
  //       let obj = {};
  //       for (let field of fieldArry) {
  //         obj[field] = 0;
  //         for (let data of filteredRecords) {
  //           for (let mth of methods) {
  //             if (mth === 'sum') {
  //               obj[field] = obj[field] + Number(data[field])
  //             } else if (mth === 'count') {
  //               obj[field] = obj[field] + 1;
  //             }
  //           }
  //         }
  //       }
  //       console.log(fieldArry, obj)
  //       setFields(fieldArry);
  //       setValues(obj);
  //       //console.log('totamount',totamount)
  //       //setTotalAmount(totamount);
  //     }
  //     setBody(filteredRecords); // Update the state with the filtered records
  //   }
  // };


  const filterRecordsByDate = () => {
    console.log('startDate:', startDate);
    console.log('endDate:', endDate);
    console.log('body:', tempBody);

    if (startDate && endDate && Array.isArray(tempBody) && tempBody.length > 0) {
      const filteredRecords = tempBody.filter((record) => {
        const visitDate = moment(record.Visit_Date, 'DD-MM-YYYY').toDate();
        const start = moment(startDate).toDate();
        const end = moment(endDate).toDate();
        return visitDate >= start && visitDate <= end;
      });

      console.log('filteredRecords:', filteredRecords);

      if (report && report.summarymethod && report.summaryfield) {
        const methods = report.summarymethod.split(", ");
        const fieldArry = report.summaryfield.split(", ");
        let obj = {};
        for (let field of fieldArry) {
          obj[field] = 0;
          for (let data of filteredRecords) {
            for (let mth of methods) {
              if (mth === 'sum') {
                obj[field] = obj[field] + Number(data[field])
              } else if (mth === 'count') {
                obj[field] = obj[field] + 1;
              }
            }
          }
        }
        console.log(fieldArry, obj)
        setFields(fieldArry);
        setValues(obj);
      }
      setBody(filteredRecords); // Update the state with the filtered records
    }
  };






  useEffect(() => {
    filterRecordsByDate();
  }, [startDate, endDate]);


  return (

    <Row className="g-0">
      <Row className="view-form">

        <Col lg={11} className="pb-3 pt-2">
          <Link className="nav-link" to="/reports">
            Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Reports</div>
          </Link>
        </Col>
        <Col></Col>
      </Row>
      <Col lg={2} className="mx-2">

      </Col>

      <Col lg={12} className="px-4">

        {body ?
          <DatatableWrapper body={body} headers={header} paginationOptionsProps={{

            initialState: {
              rowsPerPage: 100,

            }
          }}>
            <row>
              <p style={{ fontSize: "small" }}>Report Name</p>
            </row>
            <Row> <div className="col-3 col-sm-3"><b>{report.name}</b></div></Row>

            <Row className="mb-4">

              {/* Report Search */}





              <Col
                xs={20}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />


              </Col>
              <Col xs={4} lg={2} className="d-flex flex-col justify-content-end align-items-end">
                <div className="d-flex flex-column justify-content-end align-items-end mt-2">
                  <label>Start Date:</label>
                  <input type="date" value={startDate} onChange={handleStartDateChange} />
                </div>
              </Col>
              <Col xs={4} lg={2} className="d-flex flex-col justify-content-end align-items-end">
                <div className="d-flex flex-column justify-content-end align-items-end mt-2">
                  <label>End Date:</label>
                  <input type="date" value={endDate} onChange={handleEndDateChange} />
                </div>
              </Col>
              <Col xs={4} lg={5} className="d-flex flex-col justify-content-end align-items-end">
                <div className="col-3 col-sm-2">
                  <Button className="btn-sm" variant="outline-primary" onClick={() => csvDownload(ReportDataCSV)} >Export CSV</Button></div>
                <div className="col-3 col-sm-2">
                  <Button className='btn-sm' variant='danger' onClick={() => pdfRecorder()} s><i className="fa-solid fa-print"></i> PDF</Button>
                </div>
              </Col>


              {/* <Col xs={4} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                <div className="d-flex flex-column justify-content-end align-items-end mt-2">
                  <label>Start Date:</label>
                  <input type="date" value={startDate} onChange={handleStartDateChange} />
                </div>
                <div className="d-flex flex-column justify-content-end align-items-end mt-2">
                  <label>End Date:</label>
                  <input type="date" value={endDate} onChange={handleEndDateChange} />
                </div>
              </Col> */}




            </Row>
            <Table striped className="data-table" responsive="sm">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />

          </DatatableWrapper> : ''}
      </Col>
      <Col lg={2}></Col>
      <div className="App container mt-5" style={{ display: 'none' }}>

        <div id="divToPrint2">
          <ReportPDF reports={body} report={report} headers={header} refresh={refreshList} arrName={arrName} values={values}
            fields={fields} />
        </div>
        {(console.log('fields@@@', fields))}
      </div>
    </Row>
  );
}

export default ReportView