import React, { useState, useEffect } from 'react'
//import ReactApexChart from 'react-apexcharts';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';
import PieChart2 from './charts/PieChart2';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import EventEdit from './EventEdit'
import GroupBarChart from './charts/GroupBarChart';
import "./Home.css";

const Home = () => {

  const [newLead, setNewLad] = useState("");
  const [newConvertedLead, setConvertedLad] = useState("");
  const [newNotContactedLead, setNotContactedLad] = useState("");
  const [allContacts, setAllCOntacts] = useState("");
  const [totalBusiness, setTotalBusiness] = useState("");
  const [showEventModel, setShowEventModel] = useState(false);
  const calendarRef = React.createRef();
  const [reletedListTask, setReletedListTask] = useState(false);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [workingLeads, setWorkingLeads] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    //const st = "Open - Not Contactecd"
    async function init() {
      const fetchNewLeads = await SparkApi.fetchNewLeads();
      const fetchConvertedLeads = await SparkApi.fetchConvertedLeads();
      const fetchNotContactedLeads = await SparkApi.fetchNotContactedLeads();
      const fetchallcontacts = await SparkApi.fetchallcontacts();
      const fetchTotalBusiness = await SparkApi.fetchTotalBusiness();
      const leadCountByStatus = await SparkApi.fetchLeadReports('lead_count_by_status');
      console.log('*** ', leadCountByStatus)
      const allMeetings = await SparkApi.fetchAllMeetings('today');
      //console.log('allMeetings' , allMeetings)
      setTodayMeetings(allMeetings ? allMeetings : []);
      //console.log('fetchTotalBusiness:', fetchTotalBusiness);
      setNewLad(fetchNewLeads.total);
      setConvertedLad(fetchConvertedLeads.total);
      setNotContactedLad(fetchNotContactedLeads.total);
      setAllCOntacts(fetchallcontacts.total);
      setTotalBusiness(fetchTotalBusiness.total)
      //console.log("fetchNewLeads" , fetchNewLeads);
      //console.log("fetchLeads=>" + JSON.stringify(fetchNewLeads));
      //console.log("fetchallcontacts=>" + JSON.stringify(fetchallcontacts));
      //console.log("fetchallActiveusers=>" + JSON.stringify(fetchallActiveusers));
      //console.log('lead counts', leadCountByStatus);
      let allLeadStatus = JSON.parse(localStorage.getItem("lead_status"));
      let countWorking = 0;
      leadCountByStatus.forEach(function (item) {

        allLeadStatus.forEach(function (val) {
          if (val.is_converted === false && val.is_lost === false && val.label === item.leadstatus) {
            countWorking += parseInt(item.count);
          }
        })
      })


      setWorkingLeads(countWorking);


    }

    init();
  }, []);

  const createLead = () => {
    navigate(`/leads/e`);
  };


  const submitEvents = async (eventRec) => {
    //console.log('eventRec home submit', eventRec)
    setShowEventModel(false);
  }

  return (
    <Container>

      <Row >
        {/* <Col lg={3}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #f7981c' }}> */}
        {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

        {/* <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#f7981c' }}></i>
                <i class="fa-solid fa-bolt fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">NEW LEADS</h6>
                <h1 className='mb-0 d-inline '>{newLead}</h1><Badge bg="light" text="dark">Running</Badge>
              </div>
            </div>
          </Link>
        </Col> */}
        {(localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "SUPER_ADMIN") && (
          <Col lg={3}>
            <Link to="/leads/Converted" className='text-decoration-none text-reset'>
              <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #f7981c' }}>
                {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

                <span class="fa-stack fa-2x">
                  <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#EEE000' }}></i>
                  <i class="fa-solid fa-face-laugh-beam fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
                  {/* <i class="fa-regular fa-face-smile-beam"></i> */}
                </span>
                <div className="flex-grow-1">
                  <h6 className="text-muted mb-1">CONVERTED LEADS</h6>
                  <h1 className='mb-0 d-inline '>{newConvertedLead}</h1><Badge bg="light" text="dark">Running</Badge>
                </div>
              </div>
            </Link>
          </Col>)}

        <Col lg={3}>
          <Link to="/leads/Not-Contacted" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #f7981c' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#93B000' }}></i>
                <i class="fa-solid fa-person-walking-arrow-loop-left fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">PENDING CALLS</h6>
                <h1 className='mb-0 d-inline '>{newNotContactedLead}</h1><Badge bg="light" text="dark">Running</Badge>
              </div>
            </div>
          </Link>
        </Col>

        {/* <Col lg={3}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #FFD700' }}> */}
        {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

        {/* <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#FFD700' }}></i>
                <i class="fa-solid fa-coins fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">WORKING LEADS</h6>
                <h1 className='mb-0 d-inline '>{workingLeads}</h1> <Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col> */}
        {/* <Col lg={3}>
          <Link to="/contacts" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #198754' }}> */}
        {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

        {/* <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#198754' }}></i>
                <i class="fa-solid fa-user-tie fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">CUSTOMERS</h6>
                <h1 className='mb-0 d-inline '>{allContacts}</h1><Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col> */}
        {/* <Col lg={3}>
          <Link to="/users" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #4798b5' }}> */}
        {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

        {/* <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#4798b5' }}></i>
                <i class="fa-solid fa-arrow-trend-up fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">REVENUE</h6>
                <h1 className='mb-0 d-inline '>{parseFloat(totalBusiness/100000).toFixed(2)}</h1><Badge bg="light" text="dark"> ₹ Lakh</Badge>
              </div>
            </div>
          </Link>
        </Col> */}


      </Row>
      <Row className="mt-5">
        {/* <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >MONTHLY LEAD REPORT</Card.Header>
            <Card.Body>
              <Card.Text>
                <div style={{ height: '350px' }}>
                  <BarChart />
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

        </Col>  */}
        {localStorage.getItem("userrole") === "SUPER_ADMIN" && (
          <Col lg={6} className="text-center">
            <Card  className="custom-card">
              <Card.Header >LEAD TYPES</Card.Header>
              <Card.Body >
                <Card.Text>
                  <div style={{ height: '620px' }}>
                    <PieChart />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          
          </Col>
        )}
         <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >STAFF LEADS PROGRESS</Card.Header>
            <Card.Body>
              <Card.Text>
                <div style={{ height: '620px' }}>
                <GroupBarChart />
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

        </Col>
        <Row className="mt-5">
        {localStorage.getItem("userrole") === "SUPER_ADMIN" && (
          <Col lg={6} className="text-center">
            <Card  className="custom-card">
              <Card.Header >Stage Wise</Card.Header>
              <Card.Body >
                <Card.Text>
                  <div style={{ height: '510px' }}>
                    <PieChart2 />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          
          </Col>
        )}
        </Row>


        {/* <Col lg={6} className="mt-3">

          <div style={{ height: '350px', width: '650px' }}>

            <Card className=""> */}
        {/* <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>QUICK ACTIONS</Card.Header> */}
        {/* <Card.Body>
                <Row>
                  <Col>
                    <Card.Text> */}
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button className=" btn-primary btn-sm my-2 btn-block" style={{ width: '80%' }} onClick={() =>  createLead(true)}> <i class="fa-solid fa-bolt"></i> NEW LEAD</Button>

                      </div> */}
        {/* <div>
                        <Button className=" btn-custom btn-sm btn-block" style={{ width: '80%' }} onClick={() => setShowEventModel(true)}> <i class="fa-solid fa-list-check" ></i> NEW METINGS</Button>

                      </div> */}
        {/* </Card.Text>
                  </Col> */}
        {/* <Col>
                    <center>
                      <div ><i class="fa-regular fa-calendar-days fa-2x" style={{ color: "#E73F59" }}></i></div>
                      <p className='pt-2'><i>You have <Link to={"/meetings/today"}>
                      <b>{todayMeetings?.length}</b>
        </Link> meetings today</i></p>
                    </center>
                  </Col> */}
        {/* </Row>
              </Card.Body> */}
        {/* <Card.Footer className="text-muted">Followup with <a href="/leads" style={{ textDecoration: "none" }}>these</a> leads today</Card.Footer> */}
        {/* </Card>
          </div>
        </Col> */}
      </Row>
      {showEventModel && (
        <EventEdit
          show={showEventModel}
          onHide={() => setShowEventModel(false)}
          parentid="abc"
          //eventRec={event}
          table="user"
          submitEvents={submitEvents}
        />
      )}
    </Container>

  )
}

export default Home
