// import React, { useEffect, useState } from 'react';

// import {
//   Chart,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
// import SparkApi from '../../api/SparkApi';
//import { Colors } from 'chart.js';
import { Color } from 'chart.js';


import React, { useEffect, useState } from 'react';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SparkApi from '../../api/SparkApi';

// Chart.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   //Colors
//   Color
// );

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);




const GroupBarChart = () => {
  const [ownerNames, setOwnerNames] = useState([]);
  const [dataSetsArr, setDataSetsArr] = useState([]);

    const statusLabels = [
      'contacted',
      'Not-contacted',
      'Intrested',
      'Not-Intrested',
      'converted',
      'Not-converted',    
  ];

  useEffect(() => {
    const fetchData = async () => {
      const leadCountByAgent = await SparkApi.fetchLeadReports('lead_count_by_agent');
      //console.log('leadCountByAgent', leadCountByAgent);

      let groupedData = [];
      leadCountByAgent.map(obj => {
        let arr = groupedData[obj.ownername];
        if (!arr)
          arr = [];
        arr.push({ status: obj.leadstatus, count: obj.count })

        groupedData[obj.ownername] = arr;
      });

      //console.log('groupedData', groupedData);

      let finalSeries = [];
      Object.keys(groupedData).map(key => {
        let data = [...groupedData[key]];
        let series = [];
        data.forEach(function (obj) {
          //console.log('item:', obj);
          let arr = series[obj.status];
          if (!arr)
            arr = [];
          arr.push(obj.count);
          series[obj.status] = arr;
        })

        finalSeries[key] = series;
      })


      let uniqueStatus = [];
      Object.keys(finalSeries).map(key => {
        Object.keys(finalSeries[key]).map(stkey => {
          uniqueStatus.push(stkey);
        });
      });
      uniqueStatus = uniqueStatus.filter((item,
        index) => uniqueStatus.indexOf(item) === index);
      //console.log('uniqueStatus:', uniqueStatus);

      let keys = [];
      Object.keys(finalSeries).map(key => {

        uniqueStatus.forEach((stkey) => {
          let arr = keys[stkey];
          if (!arr)
            arr = [];
          let data = finalSeries[key][stkey];
          if (!data)
            data = 0;
          arr.push(data)
          keys[stkey] = arr;
        });
      });
      //console.log('finalSeries::2', keys);
      //console.log('groupedData.keySet():', Object.keys(groupedData));
      setOwnerNames(Object.keys(groupedData));

      let colors = [
        // 'rgba(81, 171, 93, 0.5)',
        // 'rgba(236, 139, 56, 0.5)',
        // 'rgba(107, 174, 214, 0.5)',
        // 'rgba(158, 154, 200, 0.5)',
        // 'rgba(115, 115, 115, 0.5)',
        // 'rgba(905, 555, 118, 0.5)',

        // 'rgba(153, 77, 153, 0.5)',
        // 'rgba(96, 153, 77, 0.5)',
        // 'rgba(88, 255, 117, 0.5)',
        // 'rgba(0, 255, 255, 0.5)',
        // 'rgba(255, 0, 0, 0.5)',
        // 'rgba(255, 99, 184, 0.5)',



        // 'rgba(200, 76, 180, 1)',  //Not-converted 1
        // 'rgba(228, 161, 27, 1)',  //Intre 2
        // 'rgba(220, 76, 100, 1)',  //Not-Intre 3
        // 'rgba(59, 113, 202, 1)',  //Not-cont 4
        // 'rgba(20, 164, 17, 1)',  //convert 5
        // 'rgba(80, 180, 211, 1)',  //contacted 6

        // 'rgba(200, 76, 180)',  // Not-converted
        // 'rgba(228, 161, 27)',  // Intre
        // 'rgba(220, 76, 100)',  // Not-Intre
        // 'rgba(59, 113, 202)',  // Not-cont
        // 'rgba(20, 164, 17)',   // convert
        // 'rgba(80, 180, 211)',  // contacted

    // Updated Color Code Below (08-May-2024)
     
      "rgb(255, 165, 0)", //not-converted
      'rgb(124, 193, 215)',  //intr
      'rgb(102, 102, 204)', //not -int
      'rgb(200, 76, 180)', //not-cont
      'rgb(20, 164, 77)',  //conv
      'rgb(255, 0, 0)', //cont

      ];
      let index = 0;
      let datasets = [];
      Object.keys(keys).forEach(function (key, index) {
        let dataset = {
          label: key,
          data: [...keys[key]],
          borderColor: colors[index],
          backgroundColor: colors[index],
        };
        datasets.push(dataset);
      });

      setDataSetsArr(datasets);
    };

    fetchData();
  }, []);

  const circularLegendCallback = (chart) => {
    const labels = chart.data.labels;
    const datasets = chart.data.datasets;
    const legendHtml = [];
  
    labels.forEach((label, index) => {
      const dataset = datasets[0]; // Assuming only one dataset for simplicity
      const backgroundColor = dataset.backgroundColor[index];
  
      legendHtml.push(
        `<li style="display: flex; align-items: center;">
           <div style="width: 20px; height: 20px; border-radius: 50%; background-color: ${backgroundColor}; margin-right: 5px;"></div>
           <span>${label}</span>
         </li>`
      );
    });
  
    return `<ul style="list-style: none; padding: 0;">${legendHtml.join('')}</ul>`;
  };
  
  

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   generateLabels: circularLegendCallback, // Use the custom legend callback
        // },
        position: 'top',
      },
     
      title: {
        display: true,
        text: 'Executive Lead Report',
        font: {
          family: 'Montserrat',
          size: 18, // Set the font size for the title
        },
      },
    },
    legendCallback: circularLegendCallback,
    scales: {
      x: {
        max: 20, // Set the maximum value for the x-axis
      },
      y: {
        ticks: {
          max: 50, // Set the maximum value for the y-axis
          stepSize: 10,
        },
      },
    },
  };


  //console.log('variable:', ownerNames);
  const labels = ownerNames;

  const data = {
    labels,
    datasets: dataSetsArr,
    //   datasets: [
    //     {
    //       label: 'Dataset 1',
    //       data: [10, 20, 40, 20, 50, 10, 30],
    //       borderColor: 'rgb(255, 99, 132)',
    //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //     },
    //     {
    //       label: 'Dataset 2',
    //       data: [12, 25, 30, 25, 40, 20, 25],
    //       borderColor: 'rgb(53, 162, 235)',
    //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //     },
    //   ],
  };

  const statusColors = {

    // Updated Color Code Below (08-May-2024)

    'contacted'    : 'rgb(200, 76, 180)', //cont
    'Not-converted': "rgb(255, 165, 0)", //not-converted
    'Intrested'    : 'rgb(124, 193, 215)',  //intr
    'Not-Intrested': 'rgb(102, 102, 204)', //not -int
    'Not-contacted': 'rgb(255, 0, 0)', //not-cont
    'converted'    : 'rgb(20, 164, 77)',  //conv
      
  };
  // const countNotConverted = 10
  // const countIntrested = 20;
  // const countNotIntrested = 15;
  // const countNotContacted = 15;
  // const dataset = {
  //   label: 'Lead Status',
  //   data: [
  //     countNotConverted,
  //     countIntrested,
  //     countNotIntrested,
  //     countNotContacted,
  //     // countConvert,
  //     // countContacted
  //   ],
  //   backgroundColor: [
  //     statusColors['Not-converted'],
  //     statusColors['Intrested'],
  //     statusColors['Not-Intrested'],
  //     statusColors['Not-contacted'],
  //     statusColors['convert'],
  //     statusColors['contacted'],
  //   ],
  // };
    

  const renderLegend = () => {
    const legendItems = statusLabels.map((statusLabel, index) => {
      const backgroundColor = statusColors[statusLabel] || 'gray'; // Use 'gray' as a default color if not defined
  
      return (
        <li key={index} className="circular-legend" style={{ display: 'inline-block', marginRight: '10px' }}>
          <div className="legend-item">
            <div className={`legend-dot dot-label-${index + 1}`} style={{ backgroundColor }} />
            <span>{statusLabel}</span>
          </div>
        </li>
      );
    });
  
    return (
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {legendItems}
      </ul>
    );
  };
  
  
  
  

  return (
    <div>
      <h2>Lead Status by User</h2>
      <div className="legend-container">
        {renderLegend()}
      </div>
      <Bar options={options} data={data} width={150} height={130} />
    </div>
  );
}



export default GroupBarChart;