/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import PubSub from 'pubsub-js';
import Select from 'react-select';
import CityState from "../constants/CityState.json";
import jwt_decode from "jwt-decode";


const LeadEdit = () => {
    const [validated, setValidated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    //eslint-disable-next-line
    const [selectedLeadSource, setSelectedLeadSource] = useState({});
    //eslint-disable-next-line
    const [selectStatus, setSelectStatus] = useState({});
    //eslint-disable-next-line
    const [selectSalutation, setSalutation] = useState({});
    const [lostReason, setLostReason] = useState(false);
    const [selectUser, setSelectUser] = useState({});
    //eslint-disable-next-line
    const [name, setName] = useState("");
    const [state, setState] = useState({});
    const [cities, setCities] = useState([]);
    //eslint-disable-next-line
    const [selectedCity, setSelectedCity] = useState('');
    const [option, setoption] = useState();
    //eslint-disable-next-line
    const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));
    //eslint-disable-next-line
    const [isdisablled, setdisablled] = useState(location?.state?.id ? true : false);
    //eslint-disable-next-line
    const industryValue = [
        { value: "", label: "--None--" },
        { value: "Agriculture", label: "Agriculture" },
        { value: "Apparel", label: "Apparel" },
        { value: "Banking", label: "Banking" },
        { value: "Chemicals", label: "Chemicals" },
        { value: "Communications", label: "Communications" },
    ];
    const salutationValue = [
        { value: "", label: "--None--" },
        { value: "Mr.", label: "Mr." },
        { value: "Ms.", label: "Ms." },
        { value: "Dr.", label: "Dr." },
        { value: "Mrs..", label: "Mrs.." },
        { value: "Prof.", label: "Prof.." },
    ]//eslint-disable-next-line
    const roleValue = [
        { value: "", label: "--None--" },
        { value: "ADMIN", label: "Admin" },
        { value: "USER", label: "User" },
        // {value:"Dr.",label:"Dr."},
        // {value:"Mrs..",label:"Mrs.."},
        // {value:"Prof.",label:"Prof.."},
    ]
    const leadSource = [
        { value: "Web", label: "Web" },
        { value: "Phone Enquiry", label: "Phone Enquiry" },
        { value: "Partner Referral", label: "Partner Referral" },
        { value: "Purchased List", label: "Purchased List" },
        { value: "Other", label: "Other" }
    ];
    const leadStatus = leadStatusArray;
    const [show, setShow] = React.useState(false);

    const [lead, setLead] = useState(location.state ? location.state : {});
    //eslint-disable-next-line
    let userInfo;
    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));

        if (location?.state) {
            let salutation = salutationValue.filter(salutation => salutation.value === location.state.salutation)
            let source = leadSource.filter(source => source.value === location.state.leadsource)
            // let industry = industryValue.filter(industry => industry.value === location.state.industry)
            let status = leadStatus.filter(status => status.value === location.state.leadstatus)

            setSalutation(salutation[0]);
            setSelectedLeadSource(source[0]);
            // setSelectIndustry(industry[0]);
            setSelectStatus(status[0]);
            setLostReason(location.state.iswon === false);

            if (lead.id) {
                let temp = {}
                temp.value = location.state.ownerid;
                temp.label = location.state.ownername;
                setoption(temp);
            } else {
                let temp = {};
                temp.value = userInfo.id;
                temp.label = userInfo.username;
                setoption(temp);
                lead.ownername = userInfo.username;
                lead.ownerid = userInfo.id;
            }

        } else {

            let temp = {};
            temp.value = userInfo.id;
            temp.label = userInfo.username;
            setoption(temp);
            lead.ownername = userInfo.username;
            lead.ownerid = userInfo.id;

        }

        async function init() {
            const fetchUser = await SparkApi.fetchUsers();
            let usr = []//eslint-disable-next-line
            fetchUser.map((item) => {
                var obj = {};
                obj.value = item.id;
                obj.label = item.username;
                usr.push(obj);
            })
            setSelectUser(usr);

            
            let st = [];//eslint-disable-next-line
            CityState.map((item) => {
                var obj = {};
                obj.value = item.state;
                obj.label = item.state;
                
                st.push(obj);

            });
            let finalStates = {};
            st = st.filter(function (currentObject) {
                if (currentObject.value in finalStates) {
                    return false;
                } else {
                    finalStates[currentObject.value] = true;
                    return true;
                }
            });
            setState(st);
        }

        init();

        //eslint-disable-next-line
    }, []);

    const handleState = (e) => {
        let filteredCities = [];
        CityState.forEach(function (obj) {
            if (obj.state === e.value) {
                filteredCities.push({
                    label: obj.name,
                    value: obj.name
                })
            }
        });
        setCities(filteredCities);
        setLead({ ...lead, 'state': e.value });
    }

   

    const handleSelectListChange = (e) => {
        setLead({ ...lead, 'city': e.value });
        setSelectedCity(e);

    }
    const handleSelectotcityChange = (e) => {
        setLead({ ...lead, 'othercity': e.target.value });
    }
    //Access all the value input values 
    const handleChange = (e) => {//eslint-disable-next-line
        let temp = { ...lead }
        setLead({ ...lead, [e.target.name]: e.target.value });

        if (e.target.name === 'leadstatus') {//eslint-disable-next-line
            leadStatus.map((status) => {
                if (status.label === e.target.value) {
                    if (status.is_lost === true) {
                        setLostReason(true);//eslint-disable-next-line
                        return;
                    } else {
                        setLostReason(false);//eslint-disable-next-line
                        return;
                    }
                }
            });

        }
        

    };

    const handleRoleChange = (e) => {
        setoption(e)
        setLead({ ...lead, 'ownerid': e.value, ownername: e.label });
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (checkRequredFields()) {
            setValidated(true);
            return;
        }



        //========= Logic to perform Create or Edit ======
        let result = {};

        let iswon = null;

        
        setLoading(true); // Stop loading spinner after the loop finishes 
        //eslint-disable-next-line
        leadStatus.map((status) => {
            if (status.label === lead.leadstatus) {
                if (status.is_converted === true) {
                    iswon = true;
                } else if (status.is_lost === true) {
                    iswon = false;
                }
            }
        });
        
        
        lead.iswon = iswon;



        if (lead.id) {
            result = await SparkApi.updateLead(lead);
            if (result.success) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                setLoading(false); // Stop loading spinner after the loop finishes
                navigate(`/leads/All/${lead.id}`, { state: lead });
            }
        } else {
            result = await SparkApi.createLead(lead);
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                setLoading(false); // Stop loading spinner after the loop finishes
                navigate(`/leads/All/${result.id}`, { state: result });
            }
        }
    };

    const checkRequredFields = () => {
        if (!option) {
            setShow(true);
            document.querySelector(".username").style.border = "1px solid red";
            return true;

        }
        //if ((lead.firstname && lead.firstname.trim() !== '') && (lead.lastname && lead.lastname.trim() !== '') && lead.phone && lead.leadstatus)
        if ((lead.firstname) && lead.description  && lead.leadstatus && lead.state &&  (lead.city !== "Other City" || lead.othercity)) {
            return false;
        }
        return true;
    }

    const handleCancel = () => {
        navigate("/leads/All/", { state: lead });
    };

    return (
        <Container className="view-form">
            <Row className="view-form">
                <Col></Col>
                <Col lg={8} className="pb-1 pt-2">
                    <Link className="nav-link" to="/leads/All/">
                        Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
                    </Link>
                </Col>
                <Col></Col>
            </Row>
            {isLoading &&
            <Container style={{ display: "flex",justifyContent: "center",alignItems: "center"}} >
                <Spinner 
                color={'#3b82f6'} 
                loading={isLoading} />
            </Container>
            }
            {!isLoading && 
                <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={9}>
                                {
                                    name === "" ? <h6>Create Lead</h6> : <h6> Edit Lead</h6>
                                }

                                <h5>{name}</h5>
                            </Col>
                            <Col lg={3} >
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        {(localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "USER") && ( 
                            <Row className="ibs-edit-form mb-4" lg={12}>

                                <Alert variant="danger" show={show} className="error-alert my-3 mx-2">
                                    Please Select Assign Staff
                                </Alert>
                                <Col lg={6} >
                                    <Form.Group >
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicCompany"
                                        >
                                            Lead Type
                                        </Form.Label>

                                        <Form.Select value={lead.title} name="title" onChange={handleChange} disabled={isdisablled} required >
                                            <option value="">--Select--</option>
                                            <option value="Architects">Architects</option>
                                            <option value="Builder">Builder</option>
                                            <option value="Retailers">Retailers</option>
                                            <option value="Dealer">Dealer</option>
                                            <option value="Distributors">Distributors</option>
                                            <option value="Applicator">Applicator</option>
                                            <option value="Engineer">Engineer</option>
                                            <option value="Customer">Customer</option>
                                            <option value="Supervisor">Supervisor</option>
                                            <option value="Shop Owner">Shop Owner</option>
                                            <option value="IHB">IHB</option>
                                            <option value="Contractor">Contractor</option>
                                            <option value="Water-Proofing">Water-Proofing</option>
                                            <option value="Other">Other</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Enter Type of Leads.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                {/* {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers') && 
                            <Col lg={2}>
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Salutation
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Select Salutation"
                                        name="salutation"
                                        value={lead.salutation}
                                        onChange={handleChange}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Ms">Ms.</option>
                                        <option value="Dr">Dr.</option>
                                        <option value="Prof">Prof.</option>
                                    </Form.Select>

                                </Form.Group>

                            </Col>} */}
                                <Col lg={6}></Col>
                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "USER" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Customer' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6}>
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFirstName"
                                            >
                                                First Name
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="firstname"
                                                required={true}
                                                placeholder="Enter First Name"
                                                value={lead.firstname}

                                                onChange={(e) => handleChange(e)}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide First Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                }
                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "USER" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Last Name
                                            </Form.Label>
                                            <Form.Control
                                                //required={true}
                                                type="text"
                                                name="lastname"
                                                placeholder="Enter LastName"
                                                value={lead.lastname}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter LastName.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                              {/* Position Change of Email and Phone As per Client Request on 09-May-2024 By Farhan Khan */}
                              {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} >
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Email
                                            </Form.Label>
                                            <Form.Control

                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                value={lead.email}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                    {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} >
                                        <Form.Group>
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFirstName"
                                            >
                                                Phone
                                            </Form.Label>
                                            <Form.Control

                                                type="number"
                                                name="phone"
                                                required
                                                placeholder="Enter Phone"
                                                value={lead.phone}
                                                //defaultValue={['Asif']}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Phone.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Applicator' || lead.title === 'Builder' || lead.title === 'Other' || lead.title === 'Contractor' || lead.title === 'IHB' || lead.title === 'Customer') &&
                                    <Col lg={6} >
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Project Stage
                                            </Form.Label>
                                            {/* <Form.Control

                                    type="text"
                                    name="project"
                                    placeholder="Enter Project Type"
                                    value={lead.project}
                                    onChange={(e) => handleChange(e)}
                                /> */}

                                            <Form.Select value={lead.project} name="project" onChange={handleChange}>
                                                <option value="">--Select--</option>
                                                <option value="Initial/Planning">Initial/Planning</option>
                                                <option value="DPC">DPC</option>
                                                <option value="GF">GF</option>
                                                <option value="FF">FF</option>
                                                <option value="SF">SF</option>
                                                <option value="Roof Casting">Roof Casting</option>
                                                <option value="Tiling/Flooring">Tiling/Flooring</option>
                                                <option value="Plaster">Plaster</option>
                                                <option value="Plumbing">Plumbing</option>
                                                <option value="Furniture">Furniture</option>
                                                <option value="Paints">Paints</option>
                                                <option value="Finishing">Finishing</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Enter Stage of Project.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Shop Owner') &&
                                    <Col lg={6} >
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Type of Dealer
                                            </Form.Label>
                                            {/* <Form.Control

                                    type="text"
                                    name="dealer"
                                    placeholder="Enter Dealer Type"
                                    value={lead.dealer}
                                    onChange={(e) => handleChange(e)}
                                /> */}

                                            <Form.Select value={lead.dealer} name="dealer" onChange={handleChange}>
                                                <option value="">--Select--</option>
                                                <option value="cement">cement</option>
                                                <option value="Tile">Tile</option>
                                                <option value="Paint">Paint</option>
                                                <option value="Steel">Steel</option>
                                                <option value="Hardware">Hardware</option>
                                                <option value="Construction">Construction</option>
                                                <option value="Chemicals">Chemicals</option>
                                                <option value="Others">Others</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Enter Dealer Type.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(lead.dealer === 'Others') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Other Dealer
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="dlrother"
                                                placeholder="Enter other type of dealer"
                                                value={lead.dlrother}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Other Dealer.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                    {(lead.title === 'Water-Proofing' ) &&
                                    <Col lg={6} >
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Type of Work
                                            </Form.Label>
                                
                                            <Form.Select value={lead.typeofwork} name="typeofwork" onChange={handleChange}>
                                                <option value="">--Select--</option>
                                                <option value="Basement">Basement</option>
                                                <option value="New House">New House</option>
                                                <option value="DPC">DPC</option>
                                                <option value="Bathrooms">Bathrooms</option>
                                                <option value="Old House">Old House</option>
                                                <option value="Commercial Project">Commercial Project</option>
                                                <option value="Water Tanks">Water Tanks</option>
                                                <option value="Others">Others</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Enter Work Type.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                    {(lead.title === 'Water-Proofing' ) &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Stage of Work
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="stageofwork"
                                                placeholder="Enter Stagework Name"
                                                value={lead.stageofwork}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Satgework Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                    {(lead.title === 'Water-Proofing' ) &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Approx Area
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="approxarea"
                                                placeholder="Enter Approx Area"
                                                value={lead.approxarea}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Approx Area.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Shop Owner') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Product
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="product"
                                                placeholder="Enter Product Name"
                                                value={lead.product}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Product Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Shop Owner') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Brand / Companies
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="brand"
                                                placeholder="Enter Brand Name"
                                                value={lead.brand}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Brand/Companies Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}




                                {/* **********************************For Customer Case**********************  */}
                                {/* Commented By Farhan Khan on 08-May-2024 As per Client Request */}
                                {/* {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Name of Customer
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="clname"
                                                placeholder="Enter Customer Name"
                                                value={lead.clname}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Customer Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>} */}{/* End Commented By Farhan Khan on 08-May-2024 As per Client Request */}

                                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Contractor Name
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="conr_nm"
                                                placeholder="Enter Contractor Name"
                                                value={lead.conr_nm}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Contractor Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Other' || lead.title === 'Customer' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group>
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFirstName"
                                            >
                                                Contractor Phone Number
                                            </Form.Label>
                                            <Form.Control

                                                type="number"
                                                name="contractor_phone"
                                                //required
                                                placeholder="Enter Contrator Phone"
                                                value={lead.contractor_phone}
                                                //defaultValue={['Asif']}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Contractor Phone.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Other' || lead.title === 'Customer' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Architect Name
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="archi_nm"
                                                placeholder="Enter Architect Name"
                                                value={lead.archi_nm}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Architect Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Other' || lead.title === 'Customer' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group>
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFirstName"
                                            >
                                                Architect Phone Number
                                            </Form.Label>
                                            <Form.Control

                                                type="number"
                                                name="archi_phone"
                                                //required
                                                placeholder="Enter Architect Phone"
                                                value={lead.archi_phone}
                                                //defaultValue={['Asif']}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Architect Phone.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                {(lead.title === 'Other' || lead.title === 'Customer' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Cement Brand
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="cementbnd"
                                                placeholder="Enter Cement Brand"
                                                value={lead.cementbnd}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter  Cement Brand.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(lead.title === 'Other' || lead.title === 'Customer' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Chemical Brand
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="chembnd"
                                                placeholder="Enter Chemical Brand"
                                                value={lead.chembnd}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Chemical Brand.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(lead.title === 'Other' || lead.title === 'Customer' || lead.title === 'IHB') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Site Engineer
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="proj_stage"
                                                placeholder="Enter Site Engineer"
                                                value={lead.proj_stage}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Site Engineer.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}



                                {/* <Col lg={6}>
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Company
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="company"
                                        placeholder="Enter Company"
                                        value={lead.company}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Company.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}

                                {/* <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Title
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="title"
                                        placeholder="Enter Title"
                                        value={lead.title}
                                        onChange={(e) => handleChange(e)}
                                    />

                                    <Form.Select value={lead.title} name="title" onChange={handleChange}>
                                        <option value="">--Select--</option>
                                        <option value="CEO">CEO</option>
                                        <option value="Director">Director</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Owner">Owner</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Executive">Executive</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Title.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}

                                {/* <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Website
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="website"
                                        placeholder="Enter Website"
                                        value={lead.website}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Fax.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}




                                {/* <Col lg={6} >

                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLeadSource"
                                    >
                                        Source
                                    </Form.Label>

                                    <Form.Select aria-label="Enter status" value={lead.leadsource} name="leadsource" onChange={handleChange}>
                                        <option value="">--Select-Source--</option>
                                        <option value="Phone">Phone</option>
                                        <option value="Partner Referral">Partner Referral</option>
                                        <option value="Purchased List">Purchased List</option>
                                        <option value="Web">Web</option>
                                        <option value="Email">Email</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="Other">Other</option>

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter  Lead Source.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}

                                
                                {/* Field Name of Firm */}
                                  {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Supervisor' || lead.title === 'Contractor' || lead.title === 'Shop Owner') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Name of Firm
                                            </Form.Label>
                                            <Form.Control
                                                // required={true}
                                                type="text"
                                                name="firm"
                                                placeholder="Enter Firm Name"
                                                value={lead.firm}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Firm Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                <Col lg={6} >
                                    <Form.Group >
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicFirstName"
                                        >
                                            Marketing Executive Name
                                        </Form.Label>
                                        <Select
                                            required
                                            isDisabled={localStorage.getItem("userrole") !== "SUPER_ADMIN" && localStorage.getItem("userrole") !== "ADMIN"}
                                            value={option}
                                            className="custom-select username"
                                            onChange={(e) => handleRoleChange(e)}
                                            options={selectUser}

                                        //value={selectSalutation}
                                        >
                                        </Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Select-Role.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>


                                {/* <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Industry
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Industry" value={lead.industry} name="industry" onChange={handleChange}>
                                        <option value="">--Select-Industry--</option>
                                        <option value="Agriculture">Agriculture</option>
                                        <option value="Apparel">Apparel</option>
                                        <option value="Banking">Banking</option>
                                        <option value="Biotechnology">Biotechnology</option>
                                        <option value="Chemicals">Chemicals</option>
                                        <option value="Communications">Communications</option>
                                        <option value="Construction">Construction</option>
                                        <option value="Consulting">Consulting</option>
                                        <option value="Education">Education</option>
                                        <option value="Electronics">Electronics</option>
                                        <option value="Energy">Energy</option>
                                        <option value="Engineering">Engineering</option>
                                        <option value="Entertainment">Entertainment</option>
                                        <option value="Environmental">Environmental</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Food and Beverage">Food and Beverage</option>
                                        <option value="Government">Government</option>
                                        <option value="Healthcare">Healthcare</option>
                                        <option value="Hospitality">Hospitality</option>
                                        <option value="Insurance">Insurance</option>
                                        <option value="Legal">Legal</option>
                                        <option value="Machinery">Machinery</option>
                                        <option value="Manufacturing">Manufacturing</option>
                                        <option value="Media">Media</option>
                                        <option value="Non Profit">Non Profit (NGO)</option>
                                        <option value="Recreation">Recreation</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Shipping">Shipping</option>
                                        <option value="Technology">Technology</option>
                                        <option value="Telecommunications">Telecommunications</option>
                                        <option value="Transportation">Transportation</option>
                                        <option value="Utilities">Utilities</option>
                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid">
                                        Enter Industry.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}



                                {/* <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Model
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentmodel" onChange={handleChange} value={lead.paymentmodel}>
                                        <option value="">--Select Payment Model--</option>
                                        
                                            <option value="Subscription">
                                                Subscription
                                            </option>
                                            <option value="One Time">
                                                One Time
                                            </option>
                                       


                                    </Form.Select>
                                </Form.Group>
                            </Col> */}
                                {/* <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Terms
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentterms" onChange={handleChange} value={lead.paymentterms}>
                                        <option value="">--Select Terms--</option>
                                        <option value="12">
                                                12 Months
                                            </option>
                                            <option value="24">
                                                24 Months
                                            </option>
                                            <option value="One Time">
                                                One Time
                                            </option>
                                            <option value="One Time with Yearly Renewal">
                                                One Time with Yearly Renewal
                                            </option>


                                    </Form.Select>
                                </Form.Group>
                            </Col> */}
                                <Col lg={6} >
                                    <Form.Group  >
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicFees"
                                        >
                                            Status
                                        </Form.Label>
                                        <Form.Select required aria-label="Enter Status" name="leadstatus" onChange={handleChange} value={lead.leadstatus}>
                                            <option value="">--Select-Status--</option>
                                            {leadStatusArray.map((item, index) => (
                                                <option value={item.label} key={index}>
                                                    {item.label}
                                                </option>
                                            ))}


                                        </Form.Select>


                                        <Form.Control.Feedback type="invalid">
                                            Enter LeadStatus.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                 <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Expected Amount (₹)
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        placeholder="Enter Expected Amount"
                                        value={lead.amount}
                                        onChange={(e) => handleChange(e)}
                                    /> 


                                    <Form.Control.Feedback type="invalid">
                                        Enter LeadStatus.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                                <Form.Control.Feedback type="invalid">
                                    Enter LeadStatus.
                                </Form.Control.Feedback>
                                {lostReason &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFees"
                                            >
                                                Lost Reason
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="lostreason"
                                                //required
                                                placeholder="Enter lost reason"
                                                value={lead.lostreason}
                                                onChange={handleChange}
                                            />

                                        </Form.Group>
                                    </Col>
                                }

                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFees"
                                            >
                                                Other
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="other"
                                                placeholder="Other"
                                                value={lead.other}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>}

                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={12} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFees"
                                            >
                                                Requirment/Remark
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                name="description"
                                                placeholder="Enter Requirment"
                                                value={lead.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>}

                                <Col lg={12} className="section-header">
                                    ADDRESS INFORMATION
                                </Col>

                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                State
                                            </Form.Label>
                                            <Select
                                                placeholder="State"
                                                className="custom-select username"
                                                required
                                                defaultValue={{ label: lead.state, value: lead.state }}
                                                onChange={handleState}
                                                options={state}

                                            >
                                            </Select>
                                            <Form.Control.Feedback type="invalid">
                                                Enter State.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6}  >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                City
                                            </Form.Label>
                                            <Select options={cities}
                                                placeholder="Enter City"
                                                className="custom-select username"
                                                required
                                                onChange={(e) => { handleSelectListChange(e, 'city') }}
                                                name="city"
                                                defaultValue={{ label: lead.city, value: lead.city }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter City.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                    {(lead.city === 'Other City') &&
                                    <Col lg={6} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicLastName"
                                            >
                                                Other City
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="othercity"
                                                placeholder="Enter other City"
                                                value={lead.othercity}
                                                //defaultValue="Ali"
                                                onChange={(e) => handleSelectotcityChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Other City.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} >
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Street
                                            </Form.Label>
                                            <Form.Control

                                                type="text"
                                                required
                                                name="street"
                                                placeholder="Enter Street"
                                                value={lead.street}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Street.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} >
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Zip / PostalCode
                                            </Form.Label>
                                            <Form.Control

                                                type="text"
                                                name="zipcode"
                                                placeholder="Enter PostalCode"
                                                value={lead.zipcode}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter PostalCode.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} className="pb-3">
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Country
                                            </Form.Label>
                                            <Form.Control

                                                type="text"
                                                name="country"
                                                placeholder="Enter Country"
                                                value={lead.country}
                                                defaultValue="India"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                {(localStorage.getItem("userrole") === "SUPER_ADMIN" || lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                                    <Col lg={6} className="pb-3">
                                        <Form.Group >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicCompany"
                                            >
                                                Landmark
                                            </Form.Label>
                                            <Form.Control

                                                type="text"
                                                required
                                                name="loc_land"
                                                placeholder="Enter Location"
                                                value={lead.loc_land}
                                                //defaultValue="India"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Location.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}

                                    {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Contractor') &&
                                <Col lg={12} className="section-header">
                                    OFFICE ADDRESS INFORMATION
                                </Col>}
                                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Contractor') &&

                                    <Col lg={12} >
                                        <Form.Group  >
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicFees"
                                            >
                                                Office Address
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="website"
                                                placeholder="Enter Office Address"
                                                value={lead.website}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>}


                                <Col className="mt-5"></Col>

                            </Row>
                       ) }
                    </Form>

                </Col>
                <Col></Col>

            </Row>
            }
            
        </Container>
    )
}
export default LeadEdit