import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";//eslint-disable-next-line
import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";//eslint-disable-next-line
import * as constants from '../constants/CONSTANT';
import PubSub from "pubsub-js";

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [lead, setLead] = useState(location.state ? location.state : {});
  //eslint-disable-next-line
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();//eslint-disable-next-line
  const [userName, setUserName] = useState("");
  const [refreshTaskList, setRefreshTaskList] = useState();//eslint-disable-next-line
  const [convertShow, setConvertShow] = useState(true);//eslint-disable-next-line
  const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));


  useEffect(() => {

    fetchLead();
    async function init() {
      const fetchUser = await SparkApi.fetchUsers();
    //eslint-disable-next-line
      let usr = [];//eslint-disable-next-line
      fetchUser.map((item) => {
        if (location.state.assignrole === item.id) {
          setUserName(item.username);
        }
      });
    }

    init();//eslint-disable-next-line
  }, []);

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/") >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      let result = await SparkApi.fetchLeadById(lead.id);
      console.log('result lead by id', result);
      if (result?.convertedcontactid) {
        setConvertShow(false);

      }
      if (result) {
        setLead(result);
        setData(result);
      } else {
        setLead({});
      }
    }
    initStudent();
  };
  const deleteLead = async () => {
    const result = await SparkApi.deleteLead(lead.id);
    if (result.success){
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record deleted successfully' });
      navigate(`/leads/All`);
    } 
  };
  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };

  //eslint-disable-next-line
  const handleConvert = async () => {
    let convertStatus = '';

    let status = leadStatusArray.filter(item => item.is_converted === true)
    console.log('status:', status);
    convertStatus = status[0].label;
    const result = await SparkApi.convertLeadToContact(lead.id, convertStatus);
    if (result) {
      let contactId = result.convertedcontactid
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Lead Convert ",
        message: "Record Convert successfully",
      });
      navigate(`/contacts/${contactId}`)
    }

  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  return (
    <div>
      {lead && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteLead}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
          <Row className="view-form">

            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/leads/All/">
                Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
              </Link>
            </Col>
            <Col></Col>
          </Row>

          <Row className="view-form">

            <Col></Col>
            <Col lg={11}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Lead
                  <h5>
                    {/* {
                      // lead.salutation +
                      //   " " +
                      lead.firstname +
                      " " +
                      lead.lastname} */}
                    {lead.firstname}
                    {lead.lastname ? ` ${lead.lastname}` : ""}


                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  {(localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "SUPER_ADMIN" || localStorage.getItem("userrole") === "USER") && (
                    <Button
                      className="btn-sm mx-2"
                      onClick={() => editLead(true)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Button>
                  )}
                  {(localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "SUPER_ADMIN" ) && (
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => setModalShow(true)}
                    >
                      Delete
                    </Button>
                  )}
                  {/* {(localStorage.getItem("userrole") === "ADMIN" || localStorage.getItem("userrole") === "SUPER_ADMIN") && (
                  convertShow === true ?
                    <Button
                      className="btn-sm mx-2"
                      variant="warning"
                      onClick={handleConvert}
                    >
                      Convert
                    </Button>
                    : ''
                  )} */}
                </Col>
              </Row>

              <Row className="py-3 ibs-edit-form">
                <Col lg={12}>
                  <Path values={leadStatusArray} selectedValue={lead.leadstatus} />
                </Col>
                <Col lg={6}>
                  <label><b>Lead Type</b></label>
                  <span>{lead.title}</span>
                </Col>
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Lead Name</b></label>
                    <span>
                      {/* {
                        // lead.salutation +
                        //   " " +
                        lead.firstname +
                        " " +
                        lead.lastname} */}
                      {lead.firstname}
                      {lead.lastname ? ` ${lead.lastname}` : ""}
                    </span>
                  </Col>}

                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Phone</b></label>
                    <span>{lead.phone || <br />}</span>
                  </Col>}
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Email</b></label>
                    <span>{lead.email}</span>
                  </Col>}
                {/* <Col lg={6}>
                  <label>Company</label>
                  <span>{lead.company}</span>
                </Col> */}
                {/* <Col lg={6}>
                  <label>Types of Lead</label>
                  <span>{lead.title}</span>
                </Col> */}
                {/* <Col lg={6}>
                  <label>Fax</label>
                  <span>{lead.fax}</span>
                </Col> */}

                {/* <Col lg={6}>
                  <label>Lead Source</label>
                  <span>{lead.leadsource}</span>
                </Col> */}

                {/* <Col lg={6}>
                  <label>User Name</label>
                  <span>{userName || <br />}</span>
                </Col> */}
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'Contractor') &&
                  <Col lg={6}>
                    <label><b>Name of Firm</b></label>
                    <span>{lead.firm || <br />}</span>
                  </Col>}

                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Contractor' || lead.title === 'IHB' || lead.title === 'Customer') &&
                  <Col lg={6}>
                    <label><b>Project Stage</b></label>
                    <span>{lead.project || <br />}</span>
                  </Col>}
                {(lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Shop Owner') &&
                  <Col lg={6}>
                    <label><b>product</b></label>
                    <span>{lead.product || <br />}</span>
                  </Col>}
                {(lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Shop Owner') &&
                  <Col lg={6}>
                    <label><b>Type of Dealer</b></label>
                    <span>{lead.dealer || <br />}</span>
                  </Col>}
                {(lead.dealer === 'Others') &&
                  <Col lg={6}>
                    <label><b>Other Dealer</b></label>
                    <span>{lead.dlrother || <br />}</span>
                  </Col>}
                {(lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Type of Work</b></label>
                    <span>{lead.typeofwork || <br />}</span>
                  </Col>}
                {(lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Stage of Work</b></label>
                    <span>{lead.stageofwork || <br />}</span>
                  </Col>}
                {(lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Approx Area</b></label>
                    <span>{lead.approxarea || <br />}</span>
                  </Col>}
                {(lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Shop Owner') &&
                  <Col lg={6}>
                    <label><b>Brand/Companies</b></label>
                    <span>{lead.brand || <br />}</span>
                  </Col>}

                {/************************ Customer Field************ */}
                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                  <Col lg={6}>
                    <label><b>Customer Name</b></label>
                    <span>{lead.clname || <br />}</span>
                  </Col>}
                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                  <Col lg={6}>
                    <label><b>Contrator Name</b></label>
                    <span>{lead.conr_nm || <br />}</span>
                  </Col>}
                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                  <Col lg={6}>
                    <label><b>Contrator Number</b></label>
                    <span>{lead.contractor_phone || <br />}</span>
                  </Col>}
                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                  <Col lg={6}>
                    <label><b>Architect Name</b></label>
                    <span>{lead.archi_nm || <br />}</span>
                  </Col>}
                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                  <Col lg={6}>
                    <label><b>Architect Number</b></label>
                    <span>{lead.archi_phone || <br />}</span>
                  </Col>}


                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                  <Col lg={6}>
                    <label>Cement Brand</label>
                    <span>{lead.cementbnd || <br />}</span>
                  </Col>}
                {(lead.title === 'Customer' || lead.title === 'Other' || lead.title === 'IHB') &&
                  <Col lg={6}>
                    <label>Chemical Brand</label>
                    <span>{lead.chembnd || <br />}</span>
                  </Col>}
                {/* {(lead.title === 'Customer') &&
                  <Col lg={6}>
                    <label>Project Stage</label>
                    <span>{lead.proj_stage || <br />}</span>
                  </Col>} */}

                {/* <Col lg={6}>
                  <label>Industry</label>
                  <span>{lead.industry}</span>
                </Col> */}

                <Col lg={6}>
                  <label><b>Amount</b></label>
                  <span>₹ {lead.amount}</span>
                </Col>
                {/* <Col lg={6}>
                  <label>Payment Model</label>
                  <span>{lead.paymentmodel}</span>
                </Col> */}
                {/* <Col lg={6}>
                  <label>Payment Terms</label>
                  <span>{lead.paymentterms}</span>
                </Col> */}
                <Col lg={6}>
                  <label><b>Status</b></label>
                  <span>{lead.leadstatus || <br />}</span>
                </Col>
                {/* {lead.iswon === true && 
                <Col lg={6}>
                  <label>Converted Contact</label>
                  <span>
                  <Link to={"/contacts/" + lead.convertedcontactid}>
        {lead.contactname}
      </Link></span>
                 
                </Col>
} */}
                {lead.iswon === false &&
                  <Col lg={6}>
                    <label><b>Lost Reason</b></label>
                    <span>{lead.lostreason || <br />}</span>
                  </Col>
                }
                <Col lg={6}>
                  <label><b>Marketing Executive Name</b></label>
                  <span><Badge bg="warning" style={{ display: "inline", color: "#000" }}>{lead.ownername}</Badge></span>
                </Col>
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'Contractor') &&
                  <Col lg={6}>
                    <label><b>Other</b> </label>
                    <span>{lead.other || <br />}</span>
                  </Col>}
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={12}>
                    <label><b>Requirement</b> </label>
                    <span>{lead.description || <br />}</span>
                  </Col>}



                <Col lg={12} className="section-header">
                  <b>ADDRESS INFORMATION</b>
                </Col>
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Address Street</b></label>
                    <span>{lead.street || <br />}</span>
                  </Col>}
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>City</b></label>
                    <span>
                      {lead.city !== null ? lead.city : ""}
                      {/* {lead.zipcode} */}
                    </span>
                  </Col>}
                {(lead.city === 'Other City') &&
                  <Col lg={6}>
                    <label><b>Other City</b></label>
                    <span>
                      {lead.othercity}
                      {/* {lead.zipcode} */}
                    </span>
                  </Col>}
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>State</b></label>
                    <span>{lead.state || <br />}</span>
                  </Col>}
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Country</b></label>
                    <span>{lead.country || <br />}</span>
                  </Col>}
                {(lead.title === 'Applicator' || lead.title === 'Other' || lead.title === 'Builder' || lead.title === 'Dealer' || lead.title === 'Retailers' || lead.title === 'Engineer' || lead.title === 'Architects' || lead.title === 'Customer' || lead.title === 'Supervisor' || lead.title === 'Shop Owner' || lead.title === 'IHB' || lead.title === 'Contractor' || lead.title === 'Water-Proofing') &&
                  <Col lg={6}>
                    <label><b>Landmark</b></label>
                    <span>{lead.loc_land || <br />}</span>
                  </Col>}

                <Col className="mt-5"></Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>


          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={lead}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}

                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={lead?.id}


                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={lead}
                />
              )}
              {reletedListTask ? <RelatedListTask refreshTaskList={refreshTaskList} parent={lead} /> : ""}

            </Card.Body>
          </Card>

        </Container>
      )}
    </div>
  );
};
export default LeadView;