import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Notification = (props) => {
  const [notifications, setNotifications] = useState(JSON.parse(localStorage.getItem("notifications")));
  console.log('notification a :', notifications );
 
  const [sns, setShow] = useState(true);

  const deleteContact = () => {
    props.deleteContact();
  }
  const deleteMedicaltest = () => {
    props.deleteMedicaltest();
  }
  const deleteTask = () => {
    props.deleteTask();
  }

  const deleteLead = () => {
    props.deleteLead();
  }

  const deleteFile = () => {
    props.deleteFile();
  }

  const deletePurchaseOrder = () => {
    props.deletePurchaseOrder();
  }

  const deleteOrder = () => {
    props.deleteOrder();
  }

  return (
  <>
    
      {notifications.map((rec, index) => {
        return  <Modal
     
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
       
      >
  
        <Alert show={true} variant="danger" className='mb-0'>
          <Alert.Heading>{rec.title}</Alert.Heading>
          <p>
           {rec.description}
          </p>
          <hr />
          <p>Contact: sales@indicrm.io , +91 9521347078</p>
        </Alert>
      </Modal>
       
   
   
   })} 
  </>
  )
}

export default Notification